<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            
            <linearGradient id="Gradient1" x1="1" x2="0" y1="0" y2="0">
                <stop class="stop1" offset="0%" />
                <stop class="stop3" offset="100%" />
            </linearGradient>

            <path d="M479.504-261Q537-261 585.5-287q48.5-26 78.5-72.4 
            6-11.6-.75-22.6-6.75-11-20.25-11H316.918Q303-393 296.5-382t-.5 22.6q30 46.4 78.5 72.4 
            48.5 26 105.004 26ZM347-578l27 27q7.636 8 17.818 8Q402-543 410-551q8-8 
            8-18t-8-18l-42-42q-8.8-9-20.9-9-12.1 0-21.1 9l-42 42q-8 7.636-8 17.818Q276-559 284-551q8 
            8 18 8t18-8l27-27Zm267 0 27 27q7.714 8 18 8t18-8q8-7.636 8-17.818Q685-579 
            677-587l-42-42q-8.8-9-20.9-9-12.1 0-21.1 9l-42 42q-8 7.714-8 18t8 18q7.636 8 17.818 
            8Q579-543 587-551l27-27ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 
            31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 
            83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-400Zm0 340q142.375 0 241.188-98.812Q820-337.625 
            820-480t-98.812-241.188Q622.375-820 480-820t-241.188 98.812Q140-622.375 140-480t98.812 
            241.188Q337.625-140 480-140Z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>


    svg { 
        fill: url(#Gradient1); 
        
        animation: animate 1s linear infinite;
        animation-direction: alternate;
    }
    .stop1 { stop-color: var(--main); }
    .stop3 { stop-color: tomato; }

    @keyframes animate {
        0%{
            transform: translateY(-10%);
            transition: ease 0.5s;
        }
        50%{
            transform: translateY(10%);
            transition: ease 0.5s;
        }
    }
</style>