<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            
            <linearGradient id="Gradient1" x1="1" x2="0" y1="0" y2="0">
                <stop class="stop1" offset="0%" />
                <stop class="stop3" offset="100%" />
            </linearGradient>

            <path d="M300-200q-24 0-42-18t-18-42v-560q0-24 18-42t42-18h440q24 0 42 18t18 42v560q0 
            24-18 42t-42 18H300Zm0-60h440v-560H300v560ZM180-80q-24 0-42-18t-18-42v-590q0-12.75 8.675-21.375 
            8.676-8.625 21.5-8.625 12.825 0 21.325 8.625T180-730v590h470q12.75 0 21.375 8.675 8.625 
            8.676 8.625 21.5Q680-97 671.375-88.5T650-80H180Zm120-180v-560 560Z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

    svg { 
        fill: url(#Gradient1); 
        animation: animate 1.5s linear infinite;
    }
    
    @keyframes animate {
        0%, 7% {
            transform: rotateZ(0);
        }
        15% {
            transform: rotateZ(-15deg);
        }
        20% {
            transform: rotateZ(10deg);
        }
        25% {
            transform: rotateZ(-10deg);
        }
        30% {
            transform: rotateZ(6deg);
        }
        35% {
            transform: rotateZ(-4deg);
        }
        40%, 100% {
            transform: rotateZ(0);
        }
    }

    .stop1 { stop-color: var(--main); }
    .stop3 { stop-color: tomato; }
</style>