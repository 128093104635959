<template>
    <div class="photos">
        
        <div v-masonry transition-duration="0.3s" 
            item-selector=".man_item" 
            horizontal-order="true"
            gutter="5"
            class="items">
            <div v-masonry-tile class="man_item" v-for="photo in photos" :key="photo.id">
                
                <div :style="{ 'height': ((width - 2.5) / 2) * photo.ratio}">
                    <ik-image 
                        loading="lazy"
                        :lqip="{ active:true }"
                        :transformation="[ { width:width } ]"
                        :src="photo.url" />
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    props: ['photos'],
    data() {
        return {
            width: 0
        }
    },
    mounted() {
        this.width = window.innerWidth
    }
}
</script>

<style scoped>

    .items {
        /* max-width: 100vw; */
        padding-top: 10px;
        background-color: #edeff0;
        /* min-height: 100vh; */
    }
    .man_item {
        width: calc(50% - 2.5px);
        margin-bottom: 5px;
        overflow: hidden;
    }
    .man_item img {
        width: 100%;
        display: block;
    }

</style>