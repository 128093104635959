<template>
    <div>


        <svg :width="size" :height="size"
            style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;" 
            version="1.1" viewBox="0 0 32 32" xml:space="preserve" 
            xmlns="http://www.w3.org/2000/svg" xmlns:serif="http://www.serif.com/" 
            xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Layer1">
                <path d="M30,7.991c0,-1.652 -1.339,-2.991 -2.991,-2.991l-22.018,0c-1.652,0 -2.991,1.339 
                -2.991,2.991c0,3.271 0,9.747 0,13.018c-0,1.652 1.339,2.991 2.991,2.991l6.676,-0c-0,0 
                3.733,2.8 3.733,2.8c0.356,0.267 0.844,0.267 1.2,-0l3.733,-2.8l6.676,-0c1.652,-0 
                2.991,-1.339 2.991,-2.991l0,-13.018Zm-20.446,9.509l2.892,-0l0.605,1.816c0.175,0.524 
                0.742,0.807 1.265,0.633c0.524,-0.175 0.807,-0.742 0.633,-1.265l-3,-9c-0.136,-0.409 
                -0.519,-0.684 -0.949,-0.684c-0.43,-0 -0.813,0.275 -0.949,0.684l-3,9c-0.174,0.523 
                0.109,1.09 0.633,1.265c0.523,0.174 1.09,-0.109 1.265,-0.633l0.605,-1.816Zm10.446,-7l-2,0c-0.552,-0 
                -1,0.448 -1,1c-0,0.552 0.448,1 1,1l1.972,0c-0.014,0.454 -0.019,1.014 0.026,1.621c-0.022,0.229 
                -0.053,0.464 -0.096,0.701c-0.226,1.257 -0.792,2.605 -2.301,3.261c-0.506,0.22 -0.738,0.81 
                -0.518,1.316c0.22,0.506 0.81,0.738 1.316,0.518c1.221,-0.531 2.048,-1.352 2.601,-2.291c0.553,0.939 
                1.38,1.76 2.601,2.291c0.506,0.22 1.096,-0.012 1.316,-0.518c0.22,-0.506 -0.012,-1.096 -0.518,-1.316c-1.509,-0.656 
                -2.075,-2.004 -2.301,-3.261c-0.043,-0.237 -0.074,-0.472 -0.096,-0.701c0.045,-0.607 0.04,-1.167 
                0.026,-1.621l1.972,0c0.552,-0 1,-0.448 1,-1c-0,-0.552 -0.448,-1 -1,-1l-2,0l0,-0.5c-0,-0.552 
                -0.448,-1 -1,-1c-0.552,0 -1,0.448 -1,1l0,0.5Zm-9.779,5l1.558,-0l-0.779,-2.338l-0.779,2.338Z"/>
            </g>
        </svg>
        
    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>