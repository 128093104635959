<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg"  :width="size" :height="size" viewBox="0 -960 960 960">
            <path d="M314-120q-25 0-42.5-17.5T254-180v-428q0-11 4.5-22t12.5-19l207-218q14-14 
            33.5-17t36.5 7q17 10 25.5 28t4.5 37l-38 180h320q23 0 41.5 18.5T920-572v82q0 11-2.5 
            25.5T910-439L794-171q-9 21-29.5 36T721-120H314Zm-177 0q-23 0-40-17t-17-40v-398q0-23 
            17-40t40-17q23 0 40 17t17 40v398q0 23-17 40t-40 17Z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>