<template>
    <div class="whole">
        <div class="post" v-if="comment.post" @click="get_post()">
            <div>
                Post by @{{comment.post.post_username}} · {{post_date}}
            </div>
            <div>
                <div class="spin" v-if="post_loading"></div>
                <span v-else>view post</span>
            </div>
        </div>
        <div class="comment">
            <div class="dp">
                <img :src="comment.user.dp"/>
            </div>
            <div class="main">
                <div class="head">
                    <div class="name" @click="open_profile()">
                        {{comment.user.firstname}} {{comment.user.lastname}}
                    </div>
                    <div class="username" @click="open_profile()">@{{comment.user.username}}</div>
                    <span>·</span>
                    <div class="time">{{date}}</div>
                    <div class="opt" @click="open_comment_options()">
                        <optionicon :size="'15px'" :style="{'height': '15px'}"/>
                    </div>
                </div>
                <div class="content">
                    {{comment.text}}
                </div>
                <div class="options">
                    <div class="icon" :class="[comment.reaction == 'like' ? 'a' : '']" @click="react('like')">
                        <like :size="'18px'"/> {{comment.stat.like}}
                    </div>
                    <div class="icon" :class="[comment.reaction == 'dislike' ? 'a' : '']" @click="react('dislike')">
                        <dislike :size="'18px'"/> {{comment.stat.dislike}}
                    </div>
                    <div class="icon" @click="open_replys()">
                        <comment :size="'18px'"/> {{comment.stat.replys}}
                    </div>
                    <div class="icon write_btn" @click="toggle_write_reply()">
                        Reply
                    </div>
                </div>
            </div>
        </div>

        <div class="write" v-if="show_write">
            <div class="my_dp">
                <img :src="$root.$data.dp"/>
            </div>
            <div class="input">
                <textarea-autosize
                    placeholder="Say something..."
                    ref="textarea"
                    v-model="text"
                />
                <loader :radius="'10'" class="l" v-if="replying"/>
                <div class="send" v-else @click="reply()">
                    <send :size="'20px'"/>
                </div>
            </div>
        </div>

        <div class="replys" v-if="show_replys">
            <div v-if="fetching_replys">
                <loader class="loader"/>
            </div>
            <div v-else>
                <reply v-for="reply in replys" :key="reply.id" :reply="reply"/>
            </div>
            <div class="empty_reply" v-if="!fetching_replys && replys.length == 0">
                <comment :size="'20px'"/>
                <div class="empty_text">This comment has no reply yet</div>
            </div>
        </div>
    </div>
</template>

<script>
import optionicon from '../../icons/option.vue';
import like from '../../icons/liked.vue';
import dislike from '../../icons/dislike.vue';
import comment from '../../icons/comment.vue';
import send from '../../icons/send.vue'

import loader from '../loader.vue';

import reply from './reply.vue'

import moment from 'moment'

export default {
    components: {
        optionicon,
        like, dislike, comment, send,
        loader,
        reply
    },
    props: ['comment'],
    data() {
        return {
            post_loading: false,
            post_date: '',

            date: '',
            show_write: false,

            replying: false,
            text: '',

            show_replys: false,
            fetching_replys: false,
            replys: []
        }
    },
    mounted() {

        moment.updateLocale('en', {
            relativeTime : {
                future: "in %s",
                past:   "%s ago",
                s  : 'Just now',
                ss : '%ds',
                m:  "1m",
                mm: "%dm",
                h:  "1h",
                hh: "%dh",
                d:  "1d",
                dd: "%dd",
                w:  "1w",
                ww: "%dw",
                M:  "1mon",
                MM: "%dmon",
                y:  "1yr",
                yy: "%dyrs"
            }
        });


        this.date = moment(this.comment.date).fromNow(true)

        if (this.comment.post) {
            this.post_date = moment(this.comment.post.post_date).fromNow(true)
        }
    },
    computed: {
        new_follow() {
            return this.$root.$data.new_follow
        },
        write_reply() {
            return this.$root.$data.write_reply
        },
        view_replys() {
            return this.$root.$data.view_replys
        }
    },
    watch: {
        new_follow(val) {
            if (this.comment.user.username == val.username) {
                this.comment.user.following = val
            }
        },
        write_reply(val) {
            if (this.comment.id == val.id) {
                this.toggle_write_reply()
            }
        },
        view_replys(val) {
            if (this.comment.id == val.id) {
                this.open_replys()
            }
        }
    },
    methods: {
        open_profile() {
            
            localStorage.setItem('profile', JSON.stringify(this.comment.user))

            setTimeout(() => {
                this.$router.push('/profile/' + this.comment.user.username)
            }, 250);
        },
        get_post() {
            if (this.post_loading) { return }

            this.post_loading = true

            let form = new FormData()
            form.append('post_id', this.comment.post_id)

            this.$http.post('/post/one', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.post_loading = false
                    let post = r.post[0]
                    localStorage.setItem('post', JSON.stringify(post))
                    this.$router.push('/post/' + post.id)
                }
            })
        },
        open_comment_options() {
            
            this.$root.$data.pop = true
            this.$root.$data.pop_prop = {
                'comment': this.comment
            }
            this.$root.$data.pop_item = 'comment_options'
        },
        toggle_write_reply() {
            this.show_write = !this.show_write

            if (this.show_write) {
                setTimeout(() => {
                    this.$refs.textarea.$el.focus()
                }, 100);
            }
        },
        react(how) {

            let form = new FormData()
            form.append('reaction', how)
            form.append('comment_id', this.comment.id)

            this.$http.post('/post/comment/react', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    if (this.comment.reaction == how) {
                        r.stat[how]--

                        this.comment.reaction = ''
                    } else {
                        r.stat[how]++

                        if (this.comment.reaction) {
                            r.stat[this.comment.reaction]--
                        }
                        this.comment.reaction = how
                    }

                    this.comment.stat = r.stat
                }
            })
        },

        reply() {

            let text = this.text.replace(/(\r\n|\r|\n){2,}/g, '$1\n');
            let trim_text = text.trim()

            if (trim_text == '') {
                this.empty(); return
            }

            this.replying = true

            let form = new FormData()
            form.append('post_id', this.comment.post_id)
            form.append('comment_id', this.comment.id)
            form.append('text', trim_text)

            this.$http.post('/post/comment/reply', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.replys.unshift({
                        'id': r.id,
                        'date': r.date,
                        'comment_id': this.comment.id,
                        'post_id': this.comment.post_id,
                        'username': this.$root.$data.username,
                        'text': trim_text,
                        'user': {
                            'username': this.$root.$data.username,
                            'firstname': this.$root.$data.firstname,
                            'lastname': this.$root.$data.lastname,
                            'dp': this.$root.$data.dp
                        }
                    })
                    this.show_replys = true
                    r.stat.replys++
                    this.comment.stat = r.stat
                    this.replyed()

                    this.text = ''
                    this.replying = false
                    this.show_write = false
                }
            })
        },
        open_replys() {

            this.show_replys = !this.show_replys
            if (!this.show_replys) { return }

            this.fetching_replys = true

            let form = new FormData()
            form.append('comment_id', this.comment.id)

            this.$http.post('/post/comment/replys', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.replys = r.replys

                    this.fetching_replys = false
                }
            })
        }
    },
    notifications: {
        empty: {
            type: 'warn',
            title: 'Empty',
            message: 'Your reply is empty'
        },
        replyed: {
            type: 'success',
            title: 'Replied',
            message: 'Your replyed has been added'
        }
    }
}
</script>

<style scoped>
    .whole {
        background-color: white;
        margin-bottom: 10px;
    }
    .post {
        padding: 8px 15px;
        border-bottom: 1px solid #ddd;
        font-size: 12px;
        color: gray;

        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .post span {
        color: var(--main);
        font-weight: 500;
    }
    .post .spin {
        margin-right: 20px;
        border-width: 2px;
        width: 10px;
        height: 10px;
    }

    .comment {
        display: grid;
        grid-template-columns: 30px auto;
        gap: 10px;
        padding: 15px;
    }

    .dp {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .dp img {
        width: 30px;
    }

    .head {
        display: flex;
        align-items: center;
        gap: 10px;
        width: calc(100vw - 70px);
        color: grey;
        font-size: 14px;
    }
    
    .name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 500;
        display: inline;
    }
    .opt {
        fill: var(--main);
        margin-left: auto;
    }
    .content {
        font-size: 15px;
        line-height: 22px;
        padding-top: 10px;
        white-space: pre-line;
        color: black;
    }

    .options {
        margin-top: 10px;
        display: flex;
        gap: 20px;
        fill: grey;
    }
    .icon {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: grey;
        gap: 5px;
        font-weight: 500;
        line-height: 5px;
    }
    .a {
        fill: var(--main);
        color: var(--main);
    }
    .write_btn {
        margin-left: auto;
        font-weight: 500;
        font-size: 12px;
        padding: 10px 20px;
        border-radius: 5px;
        background-color: var(--main);
        color: white;
    }


    .write {
        display: grid;
        grid-template-columns: max-content auto;
        gap: 10px;
        padding: 15px;
        border-top: 1px solid #ddd;
    }
    
    .my_dp {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .my_dp img {
        width: 30px;
    }

    .input {
        display: flex;
    }
    textarea {
        padding: 0px;
        width: 100%;
        box-sizing: border-box;
        box-sizing: border-box;
        border: none;
        outline: none;
        font-size: 16px;
        height: 30px;
    }
    .l {
        margin: -5px;
        margin-right: 0px;
    }
    .send {
        fill: var(--main);
    }

    .replys {
        border-top: 1px solid #ddd;
        padding-bottom: 15px;
    }
    .loader {
        padding: 50px 0px;
    }
    .empty_reply {
        padding: 45px 0px;
        text-align: center;
        fill: #555;
    }
    .empty_text {
        margin-top: 10px;
        color: gray;
        font-size: 14px;
        font-weight: 500;
    }






    .popup {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        z-index: 5;
        overscroll-behavior: contain;
    }
    .dark {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }

    .slide-enter-active {
        animation: move .3s;
    }
    .slide-leave-active {
        animation: .2s move reverse;
    }
    @keyframes move {
        from {
            margin-bottom: -50vh;
        }
        to {
            margin-bottom: 0px;
        }
    }
</style>