<template>
    <div>

        <svg style="enable-background:new 0 0 24 24;" version="1.1" viewBox="0 0 24 24" 
        :width="size" :height="size"
        xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="info"/>
            <g id="icons">
                <g id="mic">
                    <path d="M20.5,10L20.5,10c-0.8,0-1.5,0.7-1.5,1.5v0.3c0,3.6-2.6,6.7-6.1,7.2C8.7,19.5,5,16.2,5,12l0-0.5C5,10.7,4.3,10,3.5,10    
                    C2.7,10,2,10.7,2,11.5L2,12c0,4.8,3,8.9,8,9.8V22c0,1.1,0.9,2,2,2s2-0.9,2-2v-0.2c5-1,8-5.3,8-10.1v-0.2C22,10.7,21.3,10,20.5,10z    "/>
                    <path d="M12,16c2.8,0,5-2.2,5-5V5c0-2.8-2.2-5-5-5S7,2.2,7,5v6C7,13.8,9.2,16,12,16z"/>
                </g>
            </g>
        </svg>
        
    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>