<template>
    <div :class="[previous.sender == chat.sender ? 's' : 'b']">
        <div v-if="chat.type == 'info'" class="info">
            <div v-if="chat.value == 'chat_approved'">
                <b>{{ me ? 'You' : '@' + chat.sender }}</b>
                approved this chat request · {{ date }}
            </div>
        </div>
        <div v-else class="c">
            <div class="meta" v-if="me">
                <div>
                    <seen :size="'15px'" v-if="chat.seen" class="seen"/>
                    <div v-else class="icon">
                        <seen :size="'15px'" v-if="chat.delivered"/>
                        <check :size="'15px'" v-else/>
                    </div>
                </div>
                <div class="time">{{ date }}</div>
            </div>
            <div class="message" :class="[
                me ? 'sent' : 'received', 
                previous.sender != chat.sender && me ? 'right' : '',
                previous.sender != chat.sender && !me ? 'left' : '',
                ]">
                {{ chat.text }}
            </div>
            <div class="meta" v-if="!me">
                <div class="time">{{ date }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment-timezone'

import check from '../../icons/check.vue'
// import checked from '../../icons/checked.vue'
import seen from '../../icons/seen.vue'

export default {
    components: {
        check, seen
    },
    props: ['chat', 'previous'],
    computed: {
        me() {
            return this.chat.sender == this.$root.$data.username
        },
        username() {
            return this.$root.$data.username
        },
        message_delivered_info() {
            return this.$root.$data.message_delivered_info
        },
        message_seen_info() {
            return this.$root.$data.message_seen_info
        }
    },
    watch: {
        message_delivered_info(val) {
            if (val.message_id == this.chat.message_id) {
                this.chat.delivered = true
                this.chat.delivered_date = val.date
            }
        },
        message_seen_info(val) {
            if (val.message_id == this.chat.message_id) {
                this.chat.seen = true
                this.chat.seen_date = val.date
            }
        }
    },
    mounted() {

        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
        
        var m = moment.utc(this.chat.date);
        this.date = m.clone().tz(timezone).calendar({
            sameDay: '[Today ]HH:mm',
            lastDay: 'ddd HH:mm',
            lastWeek: 'ddd HH:mm',
            sameElse: 'DD/MM/YYYY HH:mm'
        })

    },
    data() {
        return {
            date: ''
        }
    }
}
</script>

<style scoped>

    .info {
        text-align: center;
        padding: 10px 20px;
        width: fit-content;
        font-size: 10px;
        background-color: var(--trans);
        border-radius: 5px;
        margin: 0 auto;
        font-weight: 500;
        color: var(--main);
    }
    .c {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .s {
        margin-top: 5px;
    }
    .b {
        margin-top: 20px;
    }


    .message {
        max-width: 80%;
        word-wrap: break-word;
        line-height: 18px;
        font-size: 15px;
        /* font-weight: 500; */
        padding: 15px 18px;
        box-sizing: border-box;
        border-radius: 5px;
        position: relative;
    }
    
    .right:before {
        content: "";
        width: 0px;
        height: 0px;
        position: absolute;
        border-left: 10px solid #edeef0;
        border-right: 10px solid transparent;
        border-top: 10px solid #edeef0;
        border-bottom: 10px solid transparent;
        right: -10px;
        top: 0px;
    }
    
    .left:before {
        content: "";
        width: 0px;
        height: 0px;
        position: absolute;
        border-left: 10px solid transparent;
        border-right: 10px solid var(--main);
        border-top: 10px solid var(--main);
        border-bottom: 10px solid transparent;
        left: -10px;
        top: 0px;
    }



    .received {
        background-color: var(--main);
        border-radius: 0px 15px 15px 15px;
        z-index: 1;
        color: white;
    }
    

    .sent {
        border-radius: 15px 0px 15px 15px;
        background-color: #edeef0;
        color: black;
    }

    .icon {
        fill: gray;
    }
    .seen {
        fill: var(--main);
    }
    

    .time {
        font-size: 11px;
        font-weight: 500;
        color: gray;
    }
</style>