<template>
    <div>

        <svg enable-background="new 0 0 24 24" id="Layer_1" version="1.0" viewBox="0 0 24 24" 
            xml:space="preserve" xmlns="http://www.w3.org/2000/svg" :width="size" :height="size"
            xmlns:xlink="http://www.w3.org/1999/xlink">
            <polyline clip-rule="evenodd" fill="none" fill-rule="evenodd" 
            points="  23,7.5 17.7,13 14.9,10.2 " :stroke="color" stroke-miterlimit="10" stroke-width="2"/>
            <circle cx="9" cy="8" r="4"/>
            <path d="M9,14c-6.1,0-8,4-8,4v2h16v-2C17,18,15.1,14,9,14z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>