import { render, staticRenderFns } from "./loved.vue?vue&type=template&id=d901bee8&scoped=true&"
import script from "./loved.vue?vue&type=script&lang=js&"
export * from "./loved.vue?vue&type=script&lang=js&"
import style0 from "./loved.vue?vue&type=style&index=0&id=d901bee8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d901bee8",
  null
  
)

export default component.exports