<template>
    <div class="home_screen">
        <div class="title">Add to home screen</div>
        <div class="main">
            <div class="text">
                Enhance your social journey with Ikotun Connect! 
                🌟 Install our PWA now for offline access, push notifications, personalized features, 
                and an overall smoother experience. Click 'Install' to step into the future of 
                social connectivity. 🚀
            </div>
            <div class="btn" @click="install_pwa()">Install</div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        async install_pwa() {
            if (this.$root.$data.pwa_install_prompt == null) {
                console.log('pwa not supported');
            }
            this.$root.$data.pwa_install_prompt.prompt()
            const { outcome } = await this.$root.$data.pwa_install_prompt.userChoice;
            
            
            if (outcome === 'accepted') {
                this.$root.$data.pwa_install_prompt = null;
                this.$emit('close')
            } else if (outcome === 'dismissed') {
                this.$emit('close')
            }
        }
    }
}
</script>

<style scoped>

    .home_screen {
        background-color: white;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        width: 100%;
        position: relative;
        z-index: 2;
        transition: .2s;
    }
    .title {
        padding: 20px;
        border-bottom: 1px solid #ddd;
        font-weight: 500;
    }
    .main {
        max-height: 50vh;
        padding: 20px;
        overflow: auto;
    }
    .text {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 20px;
    }
    .btn {
        background-color: var(--main);
        color: white;
        border-radius: 5px;
        cursor: pointer;
        padding: 12px;
        /* font-size: 14px; */
        font-weight: 500;
        text-align: center;
    }
</style>