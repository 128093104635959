<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            
            <linearGradient id="Gradient1" x1="1" x2="0" y1="0" y2="0">
                <stop class="stop1" offset="0%" />
                <stop class="stop3" offset="100%" />
            </linearGradient>

            <path d="M480-272.309q62.615 0 114.461-35.038T670.922-400H289.078q24.615 57.615 76.461 
            92.653Q417.385-272.309 480-272.309ZM312-528.462l44-42.769 42.769 42.769L432.307-562 356-639.538 
            278.462-562 312-528.462Zm249.231 0L604-571.231l44 42.769L681.538-562 604-639.538 
            527.693-562l33.538 33.538Zm-81.164 428.461q-78.836 
            0-148.204-29.92-69.369-29.92-120.682-81.21-51.314-51.291-81.247-120.629-29.933-69.337-29.933-148.173t29.92-148.204q29.92-69.369 
            81.21-120.682 51.291-51.314 120.629-81.247 69.337-29.933 148.173-29.933t148.204 
            29.92q69.369 29.92 120.682 81.21 51.314 51.291 81.247 120.629 29.933 69.337 29.933 
            148.173t-29.92 148.204q-29.92 69.369-81.21 120.682-51.291 51.314-120.629 81.247-69.337 
            29.933-148.173 29.933Z"/>

        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>


    svg { 
        fill: url(#Gradient1); 
        
        animation: animate 1s linear infinite;
        animation-direction: alternate;
    }
    .stop1 { stop-color: var(--main); }
    .stop3 { stop-color: var(--main); }

    @keyframes animate {
        0%{
            transform: translateY(-10%);
            transition: ease 0.5s;
        }
        50%{
            transform: translateY(10%);
            transition: ease 0.5s;
        }
    }
</style>