<template>
    <div>

        <svg :width="size" :height="size" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.4992 2.04903C24.5122 1.64897 24.366 1.2447 24.0607 0.93934C23.7553 0.633976 23.351 
            0.487795 22.951 0.500796C22.934 0.500267 22.9171 0.5 22.9 0.5H19C18.1716 0.5 17.5 1.17157 17.5 
            2C17.5 2.82843 18.1716 3.5 19 3.5H19.3787L17.0479 5.83074C16.1547 5.3029 15.1127 5 14 5C13.2038 
            5 12.4439 5.15506 11.7488 5.43662C12.4714 6.18313 13.0498 7.06996 13.4394 8.05228C13.621 8.01796 
            13.8084 8 14 8C15.6568 8 17 9.34315 17 11C17 12.6569 15.6568 14 14 14C13.8084 14 13.621 13.982 
            13.4394 13.9477C13.0498 14.93 12.4714 15.8169 11.7488 16.5634C12.4439 16.8449 13.2038 17 14 
            17C17.3137 17 20 14.3137 20 11C20 9.8873 19.6971 8.84534 19.1692 7.95207L21.5 5.62132V6C21.5 
            6.82843 22.1716 7.5 23 7.5C23.8284 7.5 24.5 6.82843 24.5 6V2.1C24.5 2.08295 24.4997 2.06595 
            24.4992 2.04903Z"/>
            <path clip-rule="evenodd" d="M12 11C12 13.7958 10.0878 16.1449 
            7.5 16.811V18.5H8C8.82843 18.5 9.5 19.1716 9.5 20C9.5 20.8284 8.82843 21.5 8 21.5H7.5V22.5C7.5 
            23.3284 6.82843 24 6 24C5.17157 24 4.5 23.3284 4.5 22.5V21.5H4C3.17157 21.5 2.5 20.8284 2.5 
            20C2.5 19.1716 3.17157 18.5 4 18.5H4.5V16.811C1.91216 16.1449 0 13.7958 0 11C0 7.68631 2.68629 
            5.00002 6 5.00002C9.31371 5.00002 12 7.68631 12 11ZM9 11C9 12.6569 7.65685 14 6 14C4.34315 14 3 
            12.6569 3 11C3 9.34316 4.34315 8.00002 6 8.00002C7.65685 8.00002 9 9.34316 9 11Z" />
        </svg>
        
    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>