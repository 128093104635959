<template>
    <div class="profile" v-scroll="onScroll"
        ref="container" @touchstart="swipeStart($event)" @touchend="swipeEnd($event)">

        <banner :profile="profile" :top="top"/>
        <div class="menu">
            <div class="item" :class="[c == 0 ? 'a' : '']" @click="get_posts()">Posts</div>
            <div class="item" :class="[c == 1 ? 'a' : '']" @click="get_comments()">Comments</div>
            <div class="item" :class="[c == 2 ? 'a' : '']" @click="get_photos()">Photos</div>
            <div class="item" :class="[c == 3 ? 'a' : '']" @click="about()">About</div>
            <div class="item" :class="[c == 4 ? 'a' : '']" @click="get_followers()">Followers</div>
            <div class="item" :class="[c == 5 ? 'a' : '']" @click="get_followings()">Followings</div>
        </div>
        <div class="main">
            
            <swiper ref="mySwiper" :options="swiperOptions" @slideChange="slidemoved()">
                <swiper-slide class="slide">
                    <div class="empty" v-if="loading == null && posts.length == 0">
                        <edit :size="'30px'"/>
                        <div class="empty_text">This user has no post yet</div>
                    </div>

                    <loader class="l" v-if="loading == 0"/>
                    <div class="posts" v-else>
                        <post v-for="post in posts" :key="post.id" :post="post"/>
                    </div>
                </swiper-slide>

                <swiper-slide class="slide">
                    
                    <div class="empty" v-if="loading == null && comments.length == 0">
                        <commenticon :size="'30px'"/>
                        <div class="empty_text">This user has no comment yet</div>
                    </div>

                    <loader class="l" v-if="loading == 1"/>
                    <div class="comments" v-if="loading == null && comments.length > 0">
                        <comment v-for="comment in comments" :key="comment.id" :comment="comment"/>
                    </div>
                </swiper-slide>

                <swiper-slide class="slide">
                    
                    <div class="empty" v-if="loading == null && photos.length == 0">
                        <imageicon :size="'30px'"/>
                        <div class="empty_text">This user has not added any image yet</div>
                    </div>

                    <loader class="l" v-if="loading == 2"/>
                    <photos :photos="photos" v-if="photos.length > 0"/>
                </swiper-slide>

                <swiper-slide class="slide">
                    <loader class="l" v-if="fetching"/>
                    <about v-else :profile="profile"/>
                </swiper-slide>

                <swiper-slide class="slide">
                    <loader class="l" v-if="loading == 4"/>
                    <div class="follows" v-if="followers.length > 0">
                        <user_follow v-for="user in followers" :key="user.username" :user="user"/>
                    </div>

                </swiper-slide>
                <swiper-slide class="slide">
                    <loader class="l" v-if="loading == 5"/>
                    <div class="follows" v-if="followings.length > 0">
                        <user_follow v-for="user in followings" :key="user.username" :user="user"/>
                    </div>

                </swiper-slide>
            </swiper>
        </div>
    </div>
</template>

<script>
import pull_to_refresh from '../../mixins/pull_to_refresh.js'


import Localbase from 'localbase'
let db = new Localbase('ikotun')

import banner from '../../components/profile/banner.vue';

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

import edit from '../../icons/edit.vue';
import commenticon from '../../icons/comment.vue'
import imageicon from '../../icons/image.vue';

import loader from '../../components/loader.vue';
import post from '../../components/post.vue';
import comment from '../../components/post/comment.vue';
import photos from '../../components/profile/photos.vue';
import about from '../../components/profile/about.vue'
import user_follow from '../../components/profile/user_follow.vue'

export default {
    components: {
        banner,
        post, comment, photos, about, user_follow,
        loader,
        edit, commenticon, imageicon,
        Swiper, SwiperSlide
    },
    mixins: [pull_to_refresh],
    computed: {
        you() {
            return this.$root.$data.username == this.$route.params.username
        },
        swiper() {
            return this.$refs.mySwiper.$swiper
        }
    },
    data() {
        return {
            is_refreshing: false,

            fetching: true,
            profile: {},
            posts: [],
            comments: [],
            photos: [],
            followers: [],
            followings: [],

            loading: null,
            c: 0,
            swiperOptions: {
                resistanceRatio: 0,
                // autoHeight: true
            },


            top: 0
        }
    },
    activated() {
        let profile = JSON.parse(localStorage.getItem('profile'))
        if (this.$route.params.username == profile.username) {
            this.profile = profile
            this.get_profile()
        } else {
            
            db.collection('users').doc({username: this.profile.username}).get()
            .then(doc => {
                if (doc != undefined) {
                    this.profile = doc
                    this.get_profile()
                }
                else {
                    this.profile = {
                        'username': this.$route.params.username
                    }
                    this.get_profile()
                }
            })
        }

    },
    mounted() {
        
        this.profile = JSON.parse(localStorage.getItem('profile'))

        // this.get_profile()
    },
    methods: {
        onScroll(e, pos) {
            this.top = pos.scrollTop
        },
        refresh() {
            this.is_refreshing = true

            this.posts = []
            this.comments = []
            this.photos = []
            this.followers = []
            this.followings = []

            this.get_profile()
            
            setTimeout(() => {
                this.is_refreshing = false
            }, 1000);
        },
        get_profile() {
            this.fetching = true
            
            this.get_posts()

            if (this.profile.noww) {
                
                this.save_user_to_db({
                    created: this.profile.created,
                    verified: this.profile.verified,
                    dp: this.profile.dp,
                    cover: this.profile.cover,
                    username: this.profile.username,
                    firstname: this.profile.firstname,
                    lastname: this.profile.lastname,
                    location: this.profile.location,
                    posts: this.profile.posts,
                    comments: this.profile.comments,
                    followers: this.profile.followers,
                    followings: this.profile.followings
                })
                this.fetching = false; return 
            }

            let form = new FormData()
            form.append('username', this.profile.username)
            this.$http.post('/profile', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.profile = r.user

                    if (this.you) {
                        console.log('me');
                        db.collection('auth').doc({ id: 1 }).update({
                            created: r.user.created,
                            verified: r.user.verified,
                            dp: r.user.dp,
                            cover: r.user.cover,
                            firstname: r.user.firstname,
                            lastname: r.user.lastname,
                            location: r.user.location,
                            posts: r.user.posts,
                            comments: r.user.comments,
                            followers: r.user.followers,
                            followings: r.user.followings,
                            time: new Date().getTime()
                        })
                    } else {
                        this.save_user_to_db({
                            created: r.user.created,
                            verified: r.user.verified,
                            dp: r.user.dp,
                            cover: r.user.cover,
                            username: this.profile.username,
                            firstname: r.user.firstname,
                            lastname: r.user.lastname,
                            location: r.user.location,
                            posts: r.user.posts,
                            comments: r.user.comments,
                            followers: r.user.followers,
                            followings: r.user.followings,
                            time: new Date().getTime()
                        })
                    }
                    
                    localStorage.setItem('profile', JSON.stringify(this.profile))
                    this.fetching = false
                }
            })
        },
        save_user_to_db(user) {

            db.collection('users').doc({username: this.profile.username}).get()
            .then(doc => {
                if (doc == undefined) {
                    db.collection('users').doc({username: this.profile.username}).add(user)
                } else {
                    db.collection('users').doc({username: this.profile.username}).set(user)
                }
            })
        },
        slidemoved() {
            this.c = this.swiper.activeIndex
            switch (this.c) {
                case 0:
                    this.get_posts(); break
                case 1:
                    this.get_comments(); break
                case 2:
                    this.get_photos(); break
                case 3:
                    this.about(); break
                case 4:
                    this.get_followers(); break
                case 5:
                    this.get_followings(); break
            }
        },
        get_posts() {
            if (this.c != 0) {  
                this.swiper.slideTo(0)
            }

            this.c = 0

            if (!this.is_refreshing) {
                if (this.posts.length > 0) { return }
            }

            if (this.loading == 0) { return }
            this.loading = 0

            let form = new FormData()
            form.append('username', this.profile.username)

            this.$http.post('/user/posts', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.posts = r.posts

                    if (this.c == 0) { this.loading = null}
                }
            })
        },
        get_comments() {
            if (this.c != 1) {
                this.swiper.slideTo(1)
            }

            this.c = 1
            if (this.comments.length > 0) { return }

            if (this.loading == 1) { return }
            this.loading = 1

            let form = new FormData()
            form.append('username', this.profile.username)

            this.$http.post('/user/comments', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.comments = r.comments
                    
                    if (this.c == 1) { this.loading = null}
                }
            })
        },
        get_photos() {
            if (this.c != 2) { 
                this.swiper.slideTo(2)
            }
            
            this.c = 2
            if (this.photos.length > 0) { return }

            if (this.loading == 2) { return }
            this.loading = 2

            let form = new FormData()
            form.append('username', this.profile.username)
            
            this.$http.post('/user/photos', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.photos = r.photos

                    if (this.c == 2) { this.loading = null }
                }
            })
        },
        about() {
            this.swiper.slideTo(3)
            this.c = 3
        },
        get_followers() {
            
            if (this.c != 4) { 
                this.swiper.slideTo(4)
            }
            
            this.c = 4
            if (this.followers.length > 0) { return }

            if (this.loading == 4) { return }
            this.loading = 4

            let form = new FormData()
            form.append('username', this.profile.username)

            this.$http.post('/user/followers', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.followers = r.users
                    
                    if (this.c == 4) { this.loading = null }
                }
            })
        },
        get_followings() {
            
            if (this.c != 5) { 
                this.swiper.slideTo(5)
            }
            
            this.c = 5
            if (this.followings.length > 0) { return }

            if (this.loading == 5) { return }
            this.loading = 5

            let form = new FormData()
            form.append('username', this.profile.username)

            this.$http.post('/user/followings', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.followings = r.users
                    
                    if (this.c == 5) { this.loading = null }
                }
            })
        }
    }
}
</script>

<style scoped>
    .profile {
        height: 100vh;
        overflow: auto;
        position: relative;
        background-color: white;
    }
    .menu {
        display: flex;
        width: 100vw;
        overflow: auto;
        background-color: white;
        border-bottom: 1px solid #ddd;

        position: sticky;
        z-index: 2;
        top: 60px;
        left: 0px;
    }
    .item {
        padding: 15px 15px;
        text-align: center;
        font-size: 12px;
        border-bottom: 2px solid transparent;
        transition: .3s;
    }
    .item:active {
        background-color: var(--trans);
    }
    .a {
        font-weight: 500;
        color: var(--main);
        border-bottom-color: var(--main);
    }


    
    .l {
        margin: 100px 0px;
    }
    .posts {
        padding-top: .1px;
        background-color: #edeef0;
    }
    .comments {
        padding-top: 10px;
        background-color: #edeef0;
    }

    
    .empty {
        padding: 70px 0px;
        text-align: center;
        fill: #555;
    }
    .empty_text {
        margin-top: 10px;
        color: gray;
        font-size: 14px;
        font-weight: 500;
    }


    .follows {
        background-color: #edeef0;
        padding: 15px;
        padding-bottom: 1px;
    }
</style>