<template>
    <div class="reply">
        <div class="dp">
            <img :src="reply.user.dp"/>
        </div>
        <div>
            <div class="main">
                <div class="name">
                    {{reply.user.firstname}} {{reply.user.lastname}} · 
                    <span>@{{reply.user.username}}</span> 
                </div>
                <div class="content">{{reply.text}}</div>
                <div class="foot">
                    {{date}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
export default {
    props: ['reply'],
    data() {
        return {
            date: ''
        }
    },
    mounted() {
        moment.updateLocale('en', {
            relativeTime : {
                future: "in %s",
                past:   "%s ago",
                s  : 'a few seconds',
                ss : '%d seconds',
                m:  "a minute",
                mm: "%d minutes",
                h:  "an hour",
                hh: "%d hours",
                d:  "a day",
                dd: "%d days",
                w:  "a week",
                ww: "%d weeks",
                M:  "a month",
                MM: "%d months",
                y:  "a year",
                yy: "%d years"
            }
        });
        
        this.date = moment(this.reply.date).fromNow()
    }
}
</script>

<style scoped>
    .reply {
        padding: 15px;
        display: grid;
        grid-template-columns: 30px auto;
        align-items: flex-end;
        gap: 10px;
        padding: 15px;
        padding-bottom: 0px;
    }

    .dp {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .dp img {
        width: 30px;
        /* margin-bottom: 15px; */
    }

    .main {
        background-color: #edeef0;
        padding: 10px;
        border-radius: 10px;
        border-bottom-left-radius: 0px;
        font-size: 14px;
    }
    .name {
        font-weight: 500;
        margin-bottom: 5px;
    }
    .name span {
        font-weight: 400;
        color: gray;
    }
    .content {
        line-height: 22px;
        white-space: pre-line;
    }
    .foot {
        margin-top: 3px;
        color: gray;
    }
</style>