<template>
    <div class="page">
        
        <div class="head">
            <div class="back" @click="back()">
                <back :size="'15px'"/>
            </div>
            <div class="title">Settings</div>
        </div>

        <div class="main">
            <div class="dp">
                <img :src="profile.dp"/>
            </div>

            <div class="box">
                <div class="item">
                    <div class="icon">
                        <acct_settings :size="'20px'" :style="{'height': '20px'}"/>
                    </div>
                    Account Settings
                </div>
                <div class="item">
                    <div class="icon">
                        <settings :size="'20px'" :style="{'height': '20px'}"/>
                    </div>
                    Profile Settings
                </div>
                <div class="item">
                    <div class="icon">
                        <notification :size="'20px'" :style="{'height': '20px'}"/>
                    </div>
                    Notification Settings
                </div>
                <div class="item">
                    <div class="icon">
                        <app_settings :size="'20px'" :style="{'height': '20px'}"/>
                    </div>
                    App Settings
                </div>
                <div class="item last">
                    <div class="icon">
                        <verified :size="'20px'" :style="{'height': '20px'}"/>
                    </div>
                    Premium Settings
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import back from '@/icons/back.vue';

import acct_settings from '@/icons/account.vue';

import settings from '@/icons/user.vue';
import notification from '@/icons/notification.vue';
import app_settings from '@/icons/app_settings.vue';
import verified from '@/icons/verified.vue';

export default {
    components: {
        back, acct_settings, settings, notification, app_settings, verified
    },
    data() {
        return {
            profile: {}
        }
    },
    mounted() {
        this.profile = JSON.parse(localStorage.getItem('profile'))

    },
    methods: {
        back() {
            this.$router.go(-1)
        }
    }
}
</script>

<style scoped>
    
    .page {
        background-color: white;
    }
    .head {
        display: grid;
        /* grid-template-columns: calc(50% - 40px) 50%; */
        align-items: center;
        padding: 15px 15px;
        border-bottom: 1px solid #ddd;
        fill: var(--main);

        position: relative;
    }
    .back {
        position: absolute;
        top: 0px;
        left: 0px;

        width: 50px;
        height: 50px;

        display: flex;
        align-items: center;
        justify-content: center;
        transition: .3s;
    }
    .back:active {
        background-color: #edeef0;
    }
    .title {
        font-weight: 500;
        text-align: center;
    }

    .main {
        height: calc(100vh - 50px);
        overflow: auto;
        padding: 20px;
        box-sizing: border-box;
        /* background-color: #edeff0; */
    }
    .dp {
        margin: 0 auto;
        height: 30vw;
        width: 30vw;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    }
    .dp img {
        height: 30vw;
        width: 30vw;
    }


    .box {
        border-radius: 5px;
        overflow: hidden;
        margin-top: 20px;
    }
    .item {
        display: flex;
        align-items: center;
        gap: 15px;
        background-color: #edeef0;
        padding: 15px;
        margin-bottom: 5px;
        fill: #555;
        font-size: 14px;
        font-weight: 500;
        transition: .3s;
    }
    .item:active {
        background-color: #ddd;
    }
    .last {
        margin-bottom: 0px;
    }

</style>