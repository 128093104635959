<template>
    <div class="banner">
        <transition name="slide">
            <div class="head" v-if="top > 50">
                <div class="head_back" @click="back()">
                    <back :size="'20px'"/>
                </div>
                <div class="head_dp">
                    <img :src="profile.dp"/>
                </div>
                <div class="head_mid">
                    <div class="head_name_flex">
                        <div class="head_name">
                            {{profile.firstname}} {{profile.lastname}}
                        </div>
                        <verified :size="'20px'" v-if="profile.verified" :style="{'height': '20px'}"/>
                    </div>
                    <div class="head_info">@{{profile.username}} · {{profile.posts}} posts</div>
                </div>
                <div class="head_opt" v-if="you" @click="open_settings()">
                    <acct_settings :size="'20px'"/>
                </div>
                <div class="head_opt" v-else @click="togglepop('show_opt')">
                    <optionicon :size="'20px'"/>
                </div>
            </div>
        </transition>

        <div class="cover">
            <div class="opts">
                <div class="back opt" @click="back()">
                    <back :size="'20px'" :style="{'height': '20px'}"/>
                </div>

                <div class="option opt" v-if="you" @click="open_settings()">
                    <acct_settings :size="'20px'"/>
                </div>
                <div class="option opt" v-else>
                    <optionicon :size="'20px'"/>
                </div>
            </div>

            <div class="cover_img">
                <img :src="profile.cover"/>
            </div>
            <div class="dark_cover"></div>
        </div>
        
        <div class="dp">
            <img :src="profile.dp"/>
        </div>
        <div class="main">

            <div class="name"> 
                {{profile.firstname}} {{profile.lastname}} 
                <verified :size="'20px'" v-if="profile.verified" :style="{'height': '20px'}"/>
            </div>
            <div class="username">@{{profile.username}}</div>

            <div v-if="you" class="flex two_grid">
                <!-- <div class="icon">
                    <chat :size="'20px'" :style="{'height': '20px'}"/>
                </div> -->
                <div class="btn">Followers</div>
                <div class="icon" @click="edit()">
                    <edit :size="'20px'" :style="{'height': '20px'}"/>
                </div>
            </div>
            <div v-else class="flex">
                <div class="icon" @click="send_message()">
                    <chat :size="'20px'" :style="{'height': '20px'}"/>
                </div>

                <div class="load" v-if="follow_loading">
                    <loading :height="'37px'"/>
                </div>
                <div v-else>
                    <div class="btn" v-if="following" @click="unfollow()">Unfollow</div>
                    <div class="btn" v-else @click="follow()">Follow</div>
                </div>

                <div class="icon">
                    <notification :size="'20px'" :style="{'height': '20px'}"/>
                </div>
            </div>
        </div>



        <div class="stat">
            <div class="item">
                <div class="no">{{profile.posts}}</div>
                <div class="label">Posts</div>
            </div>
            <div class="item">
                <div class="no">{{profile.comments}}</div>
                <div class="label">Comments</div>
            </div>
            <div class="item">
                <div class="no">{{profile.followers}}</div>
                <div class="label">Followers</div>
            </div>
            <div class="item">
                <div class="no">{{profile.followings}}</div>
                <div class="label">Followings</div>
            </div>
        </div>

        
        <div class="popup" v-if="pop">
            <div class="dark" @click="closepop()"></div>
            
            <transition name="slide2">
                <message v-if="show_send_message" :username="profile.username"
                    @set_chat="chat = $event; closepop()" @close="closepop()"/>
            </transition>
        </div>
    </div>
</template>

<script>
import Localbase from 'localbase'
let db = new Localbase('ikotun')

import back from '../../icons/back.vue'
import optionicon from '../../icons/option.vue';
import acct_settings from '../../icons/settings.vue';

import chat from '../../icons/chat.vue';
import notification from '../../icons/notification.vue'

import edit from '../../icons/edit.vue'

import verified from '../../icons/verified.vue'

import loading from '../loading.vue'

import message from './message.vue';


export default {
    components: {
        back, optionicon, acct_settings,
        chat, notification, edit,
        verified, loading,
        message
    },
    props: ['profile', 'top'],
    computed: {
        you() {
            return this.$root.$data.username == this.profile.username
        }
    },
    activated() {
        
        db.collection('chats').doc({user: this.profile.username}).get()
        .then(document => {
            if (document != undefined) {
                this.chat = document
            }
        })

    },
    mounted() {
        setTimeout(() => {
            if (this.profile.following.follow_id) {
                this.following = true
            }

            db.collection('chats').doc({user: this.profile.username}).get()
            .then(document => {
                if (document != undefined) {
                    this.chat = document
                }
            })

        }, 600);

    },
    
    data() {
        return {
            following: false,
            follow_loading: false,

            chat: null,
            pop: false,
            show_send_message: false
        }
    },
    methods: {
        
        togglepop() {
            this.pop = true
            setTimeout(() => {
                this.show_send_message = true
            }, 100);
        },
        closepop() {
            this.show_send_message = false
            setTimeout(() => {
                this.pop = false
            }, 200);
        },

        back() {
            this.$router.go(-1)
        },
        open_settings() {
            this.$router.push('/profile/settings')
        },
        send_message() {
            if (this.chat == null) {
                this.togglepop()
            } else {
                localStorage.setItem('chat', JSON.stringify(this.chat))
                this.$router.push('/chat/' + this.chat.chat_id)
            }
        },
        edit() {
            localStorage.setItem('profile', JSON.stringify(this.profile))

            setTimeout(() => {
                this.$router.push('/profile/edit')
            }, 200);
        },
        unfollow() {
            if (this.follow_loading) { return }
            this.follow_loading = true

            let form = new FormData()
            form.append('username', this.profile.username)

            this.$http.post('/user/unfollow', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.profile.followers--
                    this.$root.$data.followings--
                        
                    db.collection('auth').doc({ id: 1 }).update({
                        'followings': this.$root.$data.followings
                    })

                    this.following = false

                    this.unfollowed()
                }
                this.follow_loading = false
            })
        },
        follow() {
            if (this.follow_loading) { return }
            this.follow_loading = true

            let form = new FormData()
            form.append('username', this.profile.username)

            this.$http.post('/user/follow', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.following = true

                    if (r.new) {
                        this.profile.followers++
                        this.$root.$data.followings++
                        
                        db.collection('auth').doc({ id: 1 }).update({
                            'followings': this.$root.$data.followings
                        })

                    }
                    this.followed()
                }
                this.follow_loading = false
            })
        }
    },
    notifications: {
        
        unfollowed: {
            type: 'success',
            title: 'Unfollowed',
            message: 'You have unfollowed this user'
        },
        followed: {
            type: 'success',
            title: 'Followed',
            message: 'You are now following this user'
        },
    }
}
</script>

<style scoped>
    .banner {

    }


    .slide-enter-active {
        animation: .3s drop linear;
    }
    .slide-leave-active {
        animation: .3s drop linear reverse;
    }
    @keyframes drop {
        from {
            top: -50px;
        }
        to {
            top: 0px;
        }
    }


    .head {
        display: grid;
        grid-template-columns: max-content  max-content auto max-content;
        align-items: center;
        gap: 15px;
        padding: 10px 15px;
        width: 100vw;
        box-sizing: border-box;
        background-color: var(--main);
        color: white;

        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 4;
    }
    .head_back {
        fill: white;
    }
    .head_dp {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .head_dp img {
        width: 40px;
    }
    .head_mid {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .head_name_flex {
        display: flex;
        gap: 5px;
        fill: white;
    }
    .head_name {
        font-weight: 700;
        display:inline;
    }
    .head_info {
        font-size: 12px;
        color: #ccc;
    }
    .head_opt {
        margin-left: auto;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        /* background-color: white; */
        fill: white;
    }




    .cover {
        height: 30vw;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        position: relative;
        /* margin-bottom: -20px; */
    }

    .opts {
        position: absolute;
        top: 0px;
        left: 0px;
        padding: 15px;
        width: 100vw;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
    }
    .opt {
        z-index: 2;
        width: 30px;
        height: 30px;
        /* background-color: white; */
        display: flex;
        align-items: center;
        justify-content: center;
        fill: white;
        border-radius: 50%;
    }
    .cover_img {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 30vw;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .cover_img img {
        width: 100vw;
    }
    .dark_cover {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 50vw;
        background-color: var(--trans);
        background-color: rgba(0, 0, 0, 0.5);
    }

    .main {
        position: relative;
        z-index: 2;
        background-color: white;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        margin-top: calc(-15vw );
        padding-top: calc(15vw );
    }

    .dp {
        position: relative;
        z-index: 3;
        margin: 0 auto;
        height: 30vw;
        width: 30vw;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        margin-top: calc(-15vw - 20px);
        box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
        /* border: 3px solid #fff; */
    }
    .dp img {
        height: 30vw;
        width: 30vw;
    }



    .name {
        font-size: 20px;
        font-weight: 700;
        padding-top: 15px;
        text-align: center;
        /* background-color: var(--trans); */
        color: var(--main);
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        fill: var(--main);
    }
    .username {
        text-align: center;
        font-size: 14px;
        color: grey;
        /* padding-bottom: 20px; */
        /* background-color: var(--trans); */
    }

    .flex {
        /* padding: 0px 30px; */
        margin: 0 auto;
        width: 80%;
        margin-top: 15px;
        /* width: fit-content; */
        display: grid;
        grid-template-columns: max-content auto max-content;
        gap: 15px;
    }
    .two_grid {
        grid-template-columns: auto max-content;
    }
    .icon {
        height: 37px;
        width: 37px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 1px solid var(--trans);
        fill: var(--main);
    }
    .load {
        border-radius: 60px;
        overflow: hidden;
    }
    .btn {
        padding: 8px 0px;
        background-color: var(--main);
        color: white;
        border-radius: 40px;
        font-weight: 500;
        cursor: pointer;
        text-align: center;
    }

    .info {
        padding: 15px;
        font-size: 14px;
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
    }
    .each {
        fill: #555;
        display: flex;
        gap: 5px;
    }

    .stat {
        /* border-top: 1px solid #ddd; */
        margin-top: 10px;
        border-bottom: 1px solid #ddd;
        /* background-color: #edeef0; */
        padding: 10px 0px;
        display: grid;
        grid-template-columns: auto auto auto auto;
        justify-content: space-around;
        text-align: center;
    }
    .no {
        /* font-size: 20px; */
        font-weight: 500;
    }
    .label {
        color: grey;
        font-size: 12px;
    }





    .popup {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        z-index: 3;
    }
    .dark {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }

    .slide2-enter-active {
        animation: move .3s;
    }
    .slide2-leave-active {
        animation: .2s move reverse;
    }
    @keyframes move {
        from {
            margin-bottom: -50vh;
        }
        to {
            margin-bottom: 0px;
        }
    }
</style>