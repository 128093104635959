<template>
    <div class="options">
        <div class="head">
            <div class="dp">
                <img :src="request.user.dp">
            </div>
            <div class="info">
                <div class="name">
                    {{ request.user.firstname }} {{ request.user.lastname }}

                    <verified :size="'20px'" v-if="request.user.verified" :style="{'height': '20px'}"/>
                </div>
                
                <div class="f">
                    <div class="small">@{{ request.user.username }}</div>
                    <div>·</div>
                    <div class="small">{{ date }}</div>
                </div>
            </div>
        </div>
        <div class="main">
            <div class="load" v-if="loading">
                <loader/>
            </div>
            
            <chat v-for="(message, i) in messages" :chat="message" :key="message.id"
                :previous="i == 0 ? previous : messages[i - 1]"/>
        </div>
        <div class="bottom">
            <div class="btn" @click="approve()">Approve</div>
            <div class="btn red">Decline</div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';

import verified from '../../icons/verified.vue';

import loader from '../loader.vue';

import chat from './chat.vue';

export default {
    props: ['request'],
    components: {
        verified,
        loader,
        chat
    },
    data() {
        return {
            date: moment(this.request.created).fromNow(),

            loading: true,
            messages: [],
            previous: {
                sender: ''
            },
        }
    },
    created() {
        const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
            this.$emit('close')
            next(false)
        })

        this.$once('hook:destroyed', () => {
            unregisterRouterGuard()
        })
    },
    mounted() {
        let form = new FormData()
        form.append('chat_id', this.request.chat_id)

        this.$http.post('/chat/messages', form)
        .then(data => {
            let r = data.data
            if (r.success) {
                this.messages = r.messages
                this.loading = false
            }
        })
    },
    methods: {
        
        approve() {
            this.$root.$data.chat_approved = this.request

            this.$emit('close')
        },
    }
}
</script>

<style scoped>
    

    .options {
        background-color: white;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        width: 100%;
        position: relative;
        z-index: 2;
        transition: .2s;
    }
    .title {
        padding: 20px;
        border-bottom: 1px solid #ddd;
        font-weight: 500;
    }


    
    .head {
        display: flex;
        align-items: center;
        padding: 15px;
        gap: 15px;
    }
    .dp {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;

        display: flex;
        align-items: center;
        justify-content: center;
    }
    .dp img {
        width: 50px;
    }
    .name {
        font-weight: 500;

        display: flex;
        align-items: center;
        gap: 10px;
        fill: var(--main);
    }
    .f {
        display: flex;
        align-items: center;
        gap: 5px;
        margin-top: 5px;
        font-size: 12px;
        color: gray;
    }
    .small {
    }


    .main {
        min-height: 50vh;
        border-top: 1px solid #ddd;
        padding: 15px;

        background-color: white;
        box-sizing: border-box;
        box-sizing: border-box;
        overflow: auto;
        position: relative;
    }
    .load {
        padding-top: 30px;
    }




    .bottom {
        display: grid;
        grid-template-columns: calc(50% - 7.5px) calc(50% - 7.5px);
        gap: 15px;
        padding: 15px;
        border-top: 1px solid #ddd;
    }
    .btn {
        padding: 10px;
        font-size: 14px;
        font-weight: 500;

        color: white;
        background-color: var(--main);
        border-radius: 5px;
        text-align: center;
    }
    .red {
        background-color: tomato;
    }
</style>