<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            
        
            <linearGradient id="Gradient1" x1="0" x2="1" y1="0" y2="1">
                <stop class="stop1" offset="0%" />
                <stop class="stop3" offset="100%" />
            </linearGradient>

            <path d="M709.23-140.001H313.076v-479.998L578.46-883.844l33.845 33.845q6.231 6.231 10.346 16.5t4.115 
            19.346v10.154l-42.461 184h243.386q28.539 0 50.423 21.885 21.885 21.884 21.885 50.423v64.614q0 
            6.231-1.308 13.461-1.308 7.231-3.923 13.462l-114.615 270.46q-8.616 19.231-28.846 32.462-20.231 
            13.231-42.077 13.231ZM253.078-619.999v479.998H100.001v-479.998h153.077Z"/>

        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>
    
    svg { 
        fill: url(#Gradient1); 
        
        animation: animate .5s ease-out infinite;
        animation-direction: alternate;
    }
    
    @keyframes animate {
        0% {
            transform: rotateZ(-20deg);
        }
        70% {
            transform: rotateZ(10deg);
        }
        100% {
            transform: rotateZ(0deg);
        }
    }

    .stop1 { stop-color: var(--main); }
    .stop3 { stop-color: var(--main); }
</style>