<template>
    <div>

        <svg :width="size" :height="size" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
            <g id="Customer_caring">
                <path d="M35.3638,32.3727l10.1335-6.4316a14.0842,14.0842,0,0,0,6.3372-11.9268h0c0-5.918-4.1642-11.0656-9.6634-11.4572a10.12,10.12,0,0,0-9.1972,4.7212.6974.6974,0,0,1-1.1936,0,10.0831,10.0831,0,0,0-9.9449-4.64c-5.1818.7566-8.9157,5.76-8.9156,11.3758h0a14.0838,14.0838,0,0,0,6.3373,11.9268l10.1335,6.4316A5.5244,5.5244,0,0,0,35.3638,32.3727Z"/>
                <path d="M15.1052,37.15H8.5472a1.0433,1.0433,0,0,0-1.0434,1.0434V60.5751a1.0435,1.0435,0,0,0,1.0434,1.0435h6.558A2.2744,2.2744,0,0,0,17.38,59.3442v-19.92A2.2744,2.2744,0,0,0,15.1052,37.15Z"/>
                <path d="M56.3279,39.7778a3.8044,3.8044,0,0,0-3.1846-1.7158h-2.57a5.55,5.55,0,0,0-5.1455,3.4423,5.7615,5.7615,0,0,1-1.0239,1.62A8.3778,8.3778,0,0,1,37.72,46.9233H30.1081a1.35,1.35,0,0,1,0-2.6992h7.5762a5.1039,5.1039,0,0,0,2.7244-.8692l0-.0537A5.22,5.22,0,0,0,35.19,38.062h-5.586a7.7377,7.7377,0,0,0-5.4961,2.27l-.1435.1435a7.2477,7.2477,0,0,1-3.8858,2.0129v14.911H40.45A15.4305,15.4305,0,0,0,54.64,48.0522l2.0068-4.6709A3.8033,3.8033,0,0,0,56.3279,39.7778Z"/>
            </g>
        </svg>

        
    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>