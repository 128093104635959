<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            <path d="M228-174Q120-206 60-292.5T0-480q0-101 60-187.5T228-786v62q-74 26-121 93T60-480q0 84 47 
            150.5T228-236v62Zm332 14q-131 0-225.5-94.5T240-480q0-131 94.5-225.5T560-800q53 0 107.5 19t97.5 
            59l-40 41q-35-29-79-44t-86-15q-107 0-183.5 76.5T300-480q0 107 76.5 183.5T560-220q42 0 
            86-14.5t79-43.5l40 40q-43 40-97.5 59T560-160Zm247-169-43-43 83-83H523v-54h324l-83-83 43-43 
            153 153-153 153Z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>