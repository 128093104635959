<template>
    <div>
        <svg :width="size" :height="size" enable-background="new 0 0 24 24" 
        version="1.0" viewBox="0 0 24 24" 
        xml:space="preserve" xmlns="http://www.w3.org/2000/svg" 
        xmlns:xlink="http://www.w3.org/1999/xlink">
            <g>
                <path d="M10,9c0-1.7,1.3-3,3-3s3,1.3,3,3c0,1.7-1.3,3-3,3S10,10.7,10,9z 
                M13,14c-4.6,0-6,3.3-6,3.3V19h12v-1.7   C19,17.3,17.6,14,13,14z"/>
            </g>
            <g>
                <g>
                    <circle cx="19.5" cy="8.5" r="2.5"/>
                </g>
                <g>
                    <path d="M19.5,13c-1.2,0-2.1,0.3-2.8,0.8c2.3,1.1,3.2,3,3.2,3.2l0,0.1H24v-1.3C24,15.7,22.9,13,19.5,13z"/>
                </g>
            </g>
            <line fill="none" :stroke="color" stroke-miterlimit="10" stroke-width="2" x1="5" x2="5" y1="8" y2="16"/>
            <line fill="none" :stroke="color" stroke-miterlimit="10" stroke-width="2" x1="9" x2="1" y1="12" y2="12"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>