<template>
    <div class="content">
        <div v-if="post.deleted" class="box">
            <remove :size="'25px'"/>
            <div class="label">
                This post content has been deleted
            </div>
        </div>
        <div v-else>
            <div v-if="blocked" class="box">
                <blockicon :size="'25px'"/>
                <div class="label">
                    You have blocked @{{ post.user.username }} content from appearing on your feed
                </div>
                <div class="btn" @click="unblock()">Unblocked</div>
            </div>
            <div v-else>
                <div v-if="hidden" class="box">
                    <hide :size="'20px'"/>
                    <div class="label">You've prevent this post from appearing on your feed</div>
                    <div class="btn" @click="view_post()">View Post</div>
                </div>
            </div>

            <div v-if="!hidden && !blocked" class="main">

                <div class="text"
                    @click="goto_post()">

                    <div v-if="post.edited">{{ post.edit_text }}</div>
                    <div v-else>{{ post.text }}</div>
                </div>
                <div class="edited" v-if="post.edited"> Post Edited </div>

                <media :media="media"/>
            </div>
        </div>
    </div>
</template>

<script>
import Localbase from 'localbase'
let db = new Localbase('ikotun')

import remove from '../../icons/delete.vue'
import hide from '../../icons/hide.vue';
import blockicon from '../../icons/block.vue';

import media from './media.vue';

export default {
    props: ['post', 'hidden', 'blocked'],
    components: {
        remove, hide, blockicon,
        media
    },
    activated() {
        if (this.post.media.length > 5) {
            this.media = JSON.parse(this.post.media)
        }
    },
    mounted() {
        if (this.post.media.length > 5) {
            this.media = JSON.parse(this.post.media)
        }
    },
    data() {
        return {
            media: []
        }
    },
    methods: {
        
        goto_post() {
            localStorage.setItem('post', JSON.stringify(this.post))
            this.$router.push('/post/' + this.post.id)
        },
        view_post() {
            this.$emit('unhide')

            db.collection('hidden_post').doc({ post_id: this.post.id }).delete()
        },
        unblock() {
            this.$root.$data.new_unblock = {
                'username': this.post.user.username
            }
            this.$emit('unblock')
            
            db.collection('blocked_users').doc({ username: this.post.user.username }).delete()
        }
    }
}
</script>

<style scoped>

    .box {
        padding: 50px 10px;
        text-align: center;
        fill: #555;
    }
    .box .label {
        font-size: 12px;
        color: gray;
        margin-top: 5px;
    }
    .box .btn {
        color: var(--main);
        font-weight: 700;
        margin-top: 5px;
        transition: .3s;
    }
    .btn:active {
        background-color: var(--trans);
    }


    .main {
        position: relative;
    }
    .edited {
        /* position: absolute; */
        /* bottom: 2px; */
        /* right: 15px; */
        padding: 0px 15px;
        padding-bottom: 5px;
        /* background-color: var(--trans); */
        color: var(--main);
        font-size: 12px;
        font-weight: 500;
        text-align: right;
    }
    .text {
        padding: 15px;
        font-size: 15px;
        /* font-weight: 500; */
        line-height: 22px;
        white-space: pre-line;
        color: black;
        transition: .3s;
    }
    .text:active {
        background-color: var(--trans);
    }
    .big {
        font-size: 18px;
        line-height: 30px;
        /* font-weight: 500; */
    }
</style>