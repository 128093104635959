<template>
    <div>
        
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" :width="size" :height="size">
            <title/>
            <path d="M19,2H5A3,3,0,0,0,2,5V19a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V5A3,3,0,0,0,19,2ZM10,7A2,2,0,1,1,8,9,2,2,0,0,1,10,7ZM7.49,20H5a1,1,0,0,1-.94-.71l3.09-3.71a1.08,1.08,0,0,1,.79-.36,1,1,0,0,1,.78.4l1,1.33ZM20,19a1,1,0,0,1-1,1H10l5.47-7.52a1,1,0,0,1,.73-.41,1,1,0,0,1,.79.29L20,15.41Z"/>
        </svg>


    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>