<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            
            <linearGradient id="Gradient1" x1="1" x2="0" y1="0" y2="0">
                <stop class="stop1" offset="0%" />
                <stop class="stop3" offset="100%" />
            </linearGradient>

            <path d="M362.308-260.001q-30.308 0-51.307-21-21-21-21-51.308v-455.382q0-30.308 21-51.308 
            20.999-21 51.307-21h335.383q30.307 0 51.307 21 21 21 21 51.308v455.382q0 30.308-21 
            51.308t-51.307 21H362.308ZM222.309-120.003q-30.307 
            0-51.307-21-21-21-21-51.307v-515.381h59.999v515.381q0 4.616 3.846 8.462 3.847 3.847 8.462 
            3.847h395.382v59.998H222.309Z"/>

        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

    svg { 
        fill: url(#Gradient1); 
        animation: animate 1.5s linear infinite;
    }
    
    @keyframes animate {
        0%, 7% {
            transform: rotateZ(0);
        }
        15% {
            transform: rotateZ(-15deg);
        }
        20% {
            transform: rotateZ(10deg);
        }
        25% {
            transform: rotateZ(-10deg);
        }
        30% {
            transform: rotateZ(6deg);
        }
        35% {
            transform: rotateZ(-4deg);
        }
        40%, 100% {
            transform: rotateZ(0);
        }
    }

    .stop1 { stop-color: var(--main); }
    .stop3 { stop-color: var(--main); }
</style>