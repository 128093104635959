<template>
    <div class="container">
        <div class="send">
            <div class="icon">
                <emoji :size="'20px'" :style="{'height': '20px'}"/>
            </div>
            <span class="input" placeholder="Message" role="textbox" 
                contenteditable @input="onInput" ref="input_span"> </span>
            
            <div class="icon">
                <imageicon :size="'20px'" :style="{'height': '20px'}"/>
            </div>

            <div class="icon" v-if="text == '' && !loading">
                <record :size="'20px'" :style="{'height': '20px'}"/>
            </div>
            <div class="icon a" v-if="text != '' && !loading" @click="send()">
                <send :size="'20px'" :style="{'height': '20px'}"/>
            </div>
            <div class="icon" v-if="loading">
                <div class="spin"></div>
            </div>
        </div>
    </div>
</template>

<script>
import emoji from '../../icons/emoji.vue'
import imageicon from '../../icons/image.vue'
import record from '../../icons/record.vue';
import send from '../../icons/send.vue'
export default {
    components: {
        emoji, imageicon, record,
        send
    },
    props: ['can_load'],
    data() {
        return {
            value: '',
            text: '',
            loading: false
        }
    },
    methods: {
        onInput(e) {
            this.text = e.target.innerText

        },
        send() {
            if (this.can_load) {
                this.loading = true
            } else {
                this.$refs.input_span.innerText = ''
            }
            let message = {
                'type': 'text',
                'text': this.text
            }
            this.$emit('send', message)
        }
    }
}
</script>

<style scoped>
    .container {
        background-color: white;
        /* padding-top: 10px; */
    }
    .send{
        background-color: #edeef0;
        display: grid;
        grid-template-columns: 40px auto 40px 40px;
        align-items: flex-end;
        position: relative;
        z-index: 1;
        /* padding: 10px 0px; */
    }
    .input {
        padding: 15px 0px;
        border: none;
        outline: none;
        background-color: transparent;
        font-size: 16px;
        margin-left: 5px;
        max-height: 200px;
        overflow: auto;
    }
    [contenteditable=true]:empty:before{
        content: attr(placeholder);
        pointer-events: none;
        display: block; /* For Firefox */
    }
    .icon {
        height: 40px;
        margin-bottom: 5px;
        text-align: center;
        fill: #555;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .a {
        fill: var(--main);
    }
    .spin {
        width: 18px;
        height: 18px;
        border-width: 2px;
    }
</style>