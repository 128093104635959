<template>
    <div class="comment_options">
        <div class="title">Comment Options</div>
        <div class="main">
            <div class="item" @click="copy()">
                <copy :size="'20px'"/>
                <div>
                    <div class="t">Copy Comment</div>
                    <div class="label">Copy comment text content</div>
                </div>
            </div>
            <div class="item" @click="write_reply()">
                <write :size="'20px'"/>
                <div>
                    <div class="t">Reply Comment</div>
                    <div class="label">Say something about this comment</div>
                </div>
            </div>
            <div class="item" @click="view_replys()">
                <comment :size="'20px'"/>
                <div>
                    <div class="t">View Replys</div>
                    <div class="label">Checkout what others are saying</div>
                </div>
            </div>
            <div class="item" @click="view_profile()">
                <user :size="'20px'" :color="'#555'"/>
                <div>
                    <div class="t">View Profile</div>
                    <div class="label">Go to @{{comment.user.username}} profile</div>
                </div>
            </div>
            <div v-if="!you">
                <div class="item" v-if="following">
                    <following :size="'20px'" :color="'#555'"/>
                    <div>
                        <div class="t">Following</div>
                        <div class="label">You are already following @{{comment.user.username}} </div>
                    </div>
                </div>
                <div class="item" v-else>
                    <follow :size="'20px'" :color="'#555'"/>
                    <div>
                        <div class="t">Follow Author</div>
                        <div class="label">Follow @{{comment.user.username}} for better interaction</div>
                    </div>
                    <div class="spin" v-if="follow_loading"></div>
                </div>
            </div>
            <div class="item">
                <chat :size="'20px'" :color="'#555'"/>
                <div>
                    <div class="t">Message Author</div>
                    <div class="label">Send a direct message to @{{comment.user.username}} </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import Localbase from 'localbase'
let db = new Localbase('ikotun')

import copy from '../../icons/copy.vue';
import write from '../../icons/edit.vue'
import comment from '../../icons/comment.vue'
import user from '../../icons/user.vue'
import following from '../../icons/following.vue'
import follow from '../../icons/follow.vue';
import chat from '../../icons/chat.vue';

export default {
    components: {
        copy, write, comment, user, following, follow, chat
    },
    props: ['comment'],
    
    computed: {
        you() {
            return this.$root.$data.username == this.comment.user.username
        }
    },
    data() {
        return {
            follow_loading: false,
            following: false
        }
    },
    created() {
        const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
            this.$emit('close')
            next(false)
        })

        this.$once('hook:destroyed', () => {
            unregisterRouterGuard()
        })
    },
    mounted() {
        if (this.comment.user.following.follow_id) {
            this.following = true
        }
    },
    methods: {
        copy() {
            let vm = this
            navigator.clipboard.writeText(this.comment.text).then(function() {
                vm.copied()
            })

            setTimeout(() => {
                this.$emit('close')
            }, 200);
        },
        write_reply() {
            this.$root.$data.write_reply = { 'id': this.comment.id }
            
            this.$emit('close')
        },
        view_replys() {
            this.$root.$data.view_replys = { 'id': this.comment.id }

            this.$emit('close')
        },
        view_profile() {
            
            this.$emit('close')
            
            localStorage.setItem('profile', JSON.stringify(this.comment.user))

            setTimeout(() => {
                this.$router.push('/profile/' + this.comment.user.username)
            }, 250);
        },
        
        follow() {
            if (this.follow_loading) { return }
            this.follow_loading = true

            let form = new FormData()
            form.append('username', this.comment.user.username)

            this.$http.post('/user/follow', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.following = true

                    if (r.new) {
                        this.$root.$data.followings++
                        
                        db.collection('auth').doc({ id: 1 }).update({
                            'followings': this.$root.$data.followings
                        })

                        let new_follow = {
                            'username': this.comment.user.username,
                            'follow_id': r.id,
                            'follow_date': r.date
                        }

                        this.$root.$data.new_follow = new_follow

                        this.$emit('close')
                    }
                    this.followed()
                }
            })
        },
    },
    notifications: {
        
        copied: {
            type: 'success',
            title: 'Copied',
            message: 'comment text content has been copied'
        },
        followed: {
            type: 'success',
            title: 'Followed',
            message: 'You are now following this user'
        },
    }
}
</script>

<style scoped>

    .comment_options {
        background-color: white;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        width: 100%;
        position: relative;
        z-index: 2;
        transition: .2s;
    }
    .title {
        padding: 20px;
        border-bottom: 1px solid #ddd;
        font-weight: 500;
    }
    .main {
        max-height: 50vh;
        padding: 10px 0px;
        overflow: auto;
    }
    .item {
        display: grid;
        grid-template-columns: max-content auto max-content;
        align-items: center;
        gap: 20px;
        fill: #555;
        padding: 10px 20px;
    }
    .t {
        font-weight: 500;
        font-size: 14px;
    }
    .label {
        font-size: 12px;
        font-weight: 500;
        color: grey;
    }


    .spin {
        width: 20px;
        height: 20px;
        border-width: 2px;
    }
</style>