<template>
    <div class="rrr">
        <div v-if="show" class="reactions"
            
            v-closable="{
                exclude: [],
                handler: 'onClose'
            }">
            <div class="action" @click="react('like')">
                <like :size="'25px'"/>
                <div class="label">Like</div>
            </div>
            <div class="action" @click="react('love')">
                <loveicon :size="'25px'"/>
                <div class="label">Love</div>
            </div>
            <div class="action" @click="react('good')">
                <good :size="'25px'"/>
                <div class="label">Good</div>
            </div>
            <div class="action" @click="react('bad')">
                <bad :size="'25px'"/>
                <div class="label">Bad</div>
            </div>
            <div class="action" @click="react('copy')">
                <copy :size="'25px'"/>
                <div class="label">Copied</div>
            </div>
        </div>

        <div class="post_react">
            <div v-if="post.reaction == null">
                <div class="item"
                    v-longclick="() => show_react()" @click="react('love')">
                    <love :size="'20px'" :style="{'height': '20px'}"/>
                    React
                </div>
            </div>
            <div v-else :class="[reacted ? 'item_active' : '']">
                <div class="item" v-if="post.reaction == 'like'"
                    v-longclick="() => show_react()" @click="react('like')">
                    <liked v-if="reacted" :size="'20px'" :style="{'height': '20px'}"/>
                    <like v-else :size="'20px'" :style="{'height': '20px'}"/>
                    Like
                </div>
                <div class="item" v-if="post.reaction == 'love'"
                    v-longclick="() => show_react()" @click="react('love')">
                    <loved v-if="reacted" :size="'20px'" :style="{'height': '20px'}"/>
                    <loveicon v-else :size="'20px'" :style="{'height': '20px'}"/>
                    Love
                </div>
                <div class="item" v-if="post.reaction == 'good'"
                    v-longclick="() => show_react()" @click="react('good')">
                    <goodd v-if="reacted" :size="'20px'" :style="{'height': '20px'}"/>
                    <good v-else :size="'20px'" :style="{'height': '20px'}"/>
                    Good
                </div>
                <div class="item" v-if="post.reaction == 'bad'"
                    v-longclick="() => show_react()" @click="react('bad')">
                    <badd v-if="reacted" :size="'20px'" :style="{'height': '20px'}"/>
                    <bad v-else :size="'20px'" :style="{'height': '20px'}"/>
                    Bad
                </div>
                <div class="item" v-if="post.reaction == 'copy'"
                    v-longclick="() => show_react()" @click="react('copy')">
                    <copied v-if="reacted" :size="'20px'" :style="{'height': '20px'}"/>
                    <copy v-else :size="'20px'" :style="{'height': '20px'}"/>
                    Copy
                </div>
            </div>

            <div class="item" @click="$emit('write_comment')">
                <comment :size="'20px'" :style="{'height': '20px'}"/>
                Comment
            </div>
            <div class="item">
                <tip :size="'20px'" :style="{'height': '20px'}"/>
                Reward
            </div>
        </div>

    </div>
</template>

<script>
import love from '../../icons/love.vue';
import comment from '../../icons/chat2.vue';
import tip from '../../icons/tip.vue'


import like from '../../icons/animated/like.vue'
import loveicon from '../../icons/animated/love.vue';
import good from '../../icons/animated/good.vue'
import copy from '../../icons/animated/copy.vue'
import bad from '../../icons/animated/bad.vue'

import liked from '../../icons/animated/liked.vue'
import loved from '../../icons/animated/loved.vue';
import goodd from '../../icons/animated/goodd.vue'
import copied from '../../icons/animated/copied.vue'
import badd from '../../icons/animated/badd.vue'


import Vue from 'vue'
// This variable will hold the reference to
// document's click handler
let handleOutsideClick
Vue.directive('closable', {
    bind (el, binding, vnode) {
        
        handleOutsideClick = (e) => {
            e.stopPropagation()
            
            const { handler, exclude } = binding.value
            
            let clickedOnExcludedEl = false
            exclude.forEach(refName => {
                
                if (!clickedOnExcludedEl) {
                    const excludedEl = vnode.context.$refs[refName]
                    clickedOnExcludedEl = excludedEl.contains(e.target)
                }
            })
            
            if (!el.contains(e.target) && !clickedOnExcludedEl) {
                vnode.context[handler]()
            }
        }
        document.addEventListener('click', handleOutsideClick)
        document.addEventListener('touchstart', handleOutsideClick)
    },
    unbind () {
        document.removeEventListener('click', handleOutsideClick)
        document.removeEventListener('touchstart', handleOutsideClick)
    }
})


export default {
    components: {
        love, comment, tip,
        like, loveicon, good, copy, bad,
        liked, loved, goodd, copied, badd
    },
    props: ['post'],
    data() {
        return {
            show: false,
            show_main_button: true,
            
            settings: {
                copy: true,
                comment: true,
                reaction: true
            },

            reacted: false
        }
    },
    mounted() {
        
        if (this.post.settings) {
            for( let key in this.post.settings) {
                this.settings[key] = this.post.settings[key]
            }
        }

        // this.post.reaction = 'love'
        if (this.post.reaction) {
            this.reacted = true
        }
    },
    methods: {
        onClose() {
            if (this.show_main_button) {
                this.show_react()
            } else {
                this.hide_react()
            }
        },
        react_click() {
            console.log('click');
        },
        show_react() {
            if (!this.show_main_button) { return }

            console.log('longggg');
            
            this.show_main_button = false
            this.show = true
        },
        hide_react() {
            if (this.show_main_button) { return }
            this.show = false
            this.show_main_button = true
        },
        react(how) {

            this.hide_react()

            if (!this.settings.reaction) {
                this.reacting_off(); return
            }

            let old_reaction = this.post.reaction

            this.reacted = false

            if (this.post.reaction == how) {
                this.reacted = false
                this.post.reaction = null
            } else {
                this.post.reaction = how
            }

            let form = new FormData()
            form.append('reaction', how)
            form.append('post_id', this.post.id)

            this.$http.post('/post/react', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    let new_react = {
                        'id': this.post.id
                    }

                    if (old_reaction == how) {
                        r.stat[how] = parseInt(r.stat[how]) - 1
                        r.stat['reactions'] = parseInt(r.stat['reactions']) - 1

                        this.post.reaction = null
                        this.reacted = false

                        new_react['reaction'] = null
                    } else {
                        r.stat[how] = parseInt(r.stat[how]) + 1
                        r.stat['reactions'] = parseInt(r.stat['reactions']) + 1

                        if (old_reaction) {
                            r.stat[old_reaction] = parseInt(r.stat[old_reaction]) - 1
                            r.stat['reactions'] = parseInt(r.stat['reactions']) - 1
                        }
                        this.post.reaction = how
                        this.reacted = true

                        new_react['reaction'] = how
                    }

                    new_react['stat'] = r.stat
                    this.$root.$data.new_react = new_react

                    this.post.stat = r.stat
                }
            })
        },
    },
    notifications: {
        reacting_off: {
            type: 'info',
            title: 'Cannot React',
            message: 'The author has turned off reacting'
        },
    }
}
</script>

<style scoped>
    .rrr {
        position: relative;
    }
    .reactions {
        position: absolute;
        top: -55px;
        left: 10px;
        padding: 0px 5px;
        border-radius: 5px;

        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
        border-bottom: 1px solid #ddd;
        display: flex;
        justify-content: center;
        align-items: center;
        animation: enter .1s linear;
        transform-origin:bottom left;
    }
    @keyframes enter {
        from {
            transform: scale(.9);
        }
        to {
            transform: scale(1);
        }
    }
    .action {
        padding: 10px 15px;
        transition: .3s;
        text-align: center;
    }
    .action:active {
        transform: scale(.6);
    }
    .label {
        font-size: 10px;
        text-align: center;
        color: gray;
        /* font-weight: 600; */
    }
    
    .post_react {
        display: grid;
        grid-template-columns: 33.33% 33.33% 33.33%;
        /* justify-content: space-around; */
        /* padding: 0px 30px; */
        border-top: 1px solid #ddd;
    }
    .item {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        fill: #444;
        font-size: 14px;
        /* color: gray; */
        padding: 15px 0px;
        transition: .3s;
        /* font-weight: 500; */
    }
    .item:active {
        background-color: var(--trans);
        /* transform: scale(.9); */
    }
    .item_active {
        font-weight: 700;
        color: var(--main);
    }
</style>