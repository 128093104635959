<template>
    <div>

        <svg enable-background="new 0 0 32 32" version="1.1" viewBox="0 0 32 32" 
        xml:space="preserve" xmlns="http://www.w3.org/2000/svg" :width="size" :height="size"
        xmlns:xlink="http://www.w3.org/1999/xlink">
            <path d="M16,13c-1.654,0-3,1.346-3,3s1.346,3,3,3s3-1.346,3-3S17.654,13,16,13z"/>
            <path d="M6,13c-1.654,0-3,1.346-3,3s1.346,3,3,3s3-1.346,3-3S7.654,13,6,13z"/>
            <path d="M26,13c-1.654,0-3,1.346-3,3s1.346,3,3,3s3-1.346,3-3S27.654,13,26,13z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>