<template>
    <div class="post">
        <div class="head">
            <div class="dp">
                <img :src="post.user.dp"/>
            </div>
            <div @click="goto_profile()">
                <div class="flex">
                    <div class="name">{{post.user.firstname}} {{post.user.lastname}}</div>
                    <verified :size="'18px'" :style="{'height': '18px'}" v-if="post.user.verified"/>
                </div>
                <div class="info">
                    @{{post.user.username}} · {{date}}
                </div>
            </div>
            <div class="opt" @click="open_post_options()">
                <optionicon :size="'15px'" :style="{'height': '15px'}"/>
            </div>
        </div>

        <post_content :post="post" :hidden="hidden" :blocked="blocked"
            @unhide="hidden = false"/>

        <div class="stat">
            <div>
                <b>{{post.stat.reactions}}</b> <span class="g"> Reactions </span> · 
                <b>{{post.stat.comments}}</b> <span class="g">Comments</span>  · 
                <b>0</b> <span class="g">Points</span> 
            </div>
            <!-- <div class="click" @click="write_comment()">Write comment</div> -->
        </div>
        <!-- <div class="reaction">
            <div class="action" :class="[post.reaction == 'like' ? 'a' : '']" @click="react('like')">
                <img src="../assets/reactions/like.png"/>
            </div>
            <div class="action" :class="[post.reaction == 'laugh' ? 'a' : '']" @click="react('laugh')">
                <img src="../assets/reactions/laugh.png"/>
            </div>
            <div class="action" :class="[post.reaction == 'waw' ? 'a' : '']" @click="react('waw')">
                <img src="../assets/reactions/waw.png"/>
            </div>
            <div class="action" :class="[post.reaction == 'sad' ? 'a' : '']" @click="react('sad')">
                <img src="../assets/reactions/sad.png"/>
            </div>
            <div class="action" :class="[post.reaction == 'angry' ? 'a' : '']" @click="react('angry')">
                <img src="../assets/reactions/angry.png"/>
            </div>
        </div> -->

        <post_react :post="post" @write_comment="write_comment()"/>

        <div class="write_comment" v-if="show_write">
            <div class="dp">
                <img :src="$root.$data.dp"/>
            </div>
            <textarea placeholder="Write a comment..." ref="textarea" v-model="text"></textarea>
            <div>
                <div class="spin" v-if="commenting"></div>
                <div class="send" v-else @click="new_comment()">
                    <send :size="'20px'"/>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import Localbase from 'localbase'
let db = new Localbase('ikotun')

import verified from '../icons/verified.vue';
import optionicon from '../icons/option.vue';

import post_content from './post/content.vue';
import post_react from './post/post_react.vue';

import send from '../icons/send.vue';


import moment from 'moment-timezone'

export default {
    components: {
        verified, optionicon,
        post_content, post_react,
        send,
    },
    props: ['post'],
    data() {
        return {
            date: '',
            reaction: '',

            pop: false,
            show_write: false,
            show_opt: false,

            text: '',
            commenting: false,

            blocked: false,
            hidden: false,
            settings: {
                copy: true,
                comment: true,
                reaction: true
            }
        }
    },
    computed: {
        new_follow() {
            return this.$root.$data.new_follow
        },
        new_hide() {
            return this.$root.$data.new_hide
        },
        new_block() {
            return this.$root.$data.new_block
        },
        new_unblock() {
            return this.$root.$data.new_unblock
        },
        new_edit() {
            return this.$root.$data.new_edit
        },
        new_delete() {
            return this.$root.$data.new_delete
        },
        new_react() {
            return this.$root.$data.new_react
        }
    },
    watch: {
        new_follow(val) {
            if (this.post.user.username == val.username) {
                this.post.user.following = val
            }
        },
        new_hide(val) {
            if (this.post.id == val.id) {
                this.hidden = true
            }
        },
        new_block(val) {
            if (this.post.user.username == val.username) {
                this.blocked = true
            }
        },
        new_unblock(val) {
            if (this.post.user.username == val.username) {
                this.blocked = false
            }
        },
        new_edit(val) {
            if (this.post.id == val.id) {
                this.post.edited = true
                this.post.edit_text = val.edit_text
                this.post.edit_date = val.edit_date
            }
        },
        new_delete(val) {
            if (this.post.id == val.id) {
                this.post.deleted = true
                this.post.delete = {
                    date: val.date,
                    by: val.by
                }
            }
        },
        new_react(val) {
            if (this.post.id == val.id) {
                this.post.reaction = val.reaction
                this.post.stat = val.stat
            }
        }
    },
    mounted() {
        this.date = moment(this.post.date).fromNow()

        if (this.post.settings) {
            for( let key in this.post.settings) {
                this.settings[key] = this.post.settings[key]
            }
        }
        
        db.collection('blocked_users').doc({ username: this.post.user.username }).get()
        .then(doc => {
            if (doc) {
                this.blocked = true
            }
        })

        db.collection('hidden_post').doc({ post_id: this.post.id }).get()
        .then(doc => {
            if (doc) {
                this.hidden = true
            }
        })
    },
    methods: {
        goto_profile() {
            localStorage.setItem('profile', JSON.stringify(this.post.user))

            setTimeout(() => {
                this.$router.push('/profile/' + this.post.user.username)
            }, 250);
        },
        react(how) {

            if (!this.settings.reaction) {
                this.reacting_off(); return
            }

            let form = new FormData()
            form.append('reaction', how)
            form.append('post_id', this.post.id)

            this.$http.post('/post/react', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    if (this.post.reaction == how) {
                        r.stat[how]--
                        r.stat['reactions']--

                        this.post.reaction = ''
                    } else {
                        r.stat[how]++
                        r.stat['reactions']++

                        if (this.post.reaction) {
                            r.stat[this.post.reaction]--
                            r.stat['reactions']--
                        }
                        this.post.reaction = how
                    }

                    this.post.stat = r.stat
                }
            })
        },
        write_comment() {
            if (!this.settings.comment) {
                this.comment_off(); return 
            }

            this.show_write = !this.show_write
            if (this.show_write) {
                setTimeout(() => {
                    this.$refs.textarea.focus()
                }, 200);
            }
        },
        new_comment() {
            
            let text = this.text.replace(/(\r\n|\r|\n){2,}/g, '$1\n');
            let trim_text = text.trim()

            if (trim_text == '') {
                this.empty(); return
            }

            this.commenting = true

            let form = new FormData()
            form.append('text', trim_text)
            form.append('post_id', this.post.id)

            this.$http.post('/post/comment', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    r.stat.comments++
                    this.post.stat = r.stat
                    this.commented()
                    
                    this.commenting = false
                    this.text = ''
                }
            })
        },
        open_post_options() {
            this.$root.$data.pop = true
            this.$root.$data.pop_prop = {
                'post': this.post,
                'settings': this.settings,
                'full': true
            }
            this.$root.$data.pop_item = 'post_options'
        },
        view_comment() {

            setTimeout(() => {
                this.goto_post()
            }, 300);
        },
        goto_post() {
            localStorage.setItem('post', JSON.stringify(this.post))
            this.$router.push('/post/' + this.post.id)
        }
    },
    notifications: {
        commented: {
            type: 'success',
            title: 'Success',
            message: 'Your comment has been added'
        },

        reacting_off: {
            type: 'info',
            title: 'Cannot React',
            message: 'The author has turned off reacting'
        },
        comment_off: {
            type: 'info',
            title: 'Cannot Comment',
            message: 'The author has turned off commenting'
        },
        
        empty: {
            'type': 'warn',
            'title': 'Empty',
            'message': 'Your comment cannot be empty'
        }
    }
}
</script>

<style scoped>
    .post {
        background-color: white;
        margin-top: 10px;
    }
    .head {
        display: flex;
        align-items: center;
        gap: 15px;
        padding: 15px;
        border-bottom: 1px solid #ddd;
    }
    
    .dp {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .dp img {
        width: 40px;
    }

    .flex {
        display: flex;
        align-items: center;
        gap: 5px;
        fill: var(--main);
    }
    .name {
        font-weight: 700;
        transition: .3s;
    }
    .name:active {
        background-color: var(--trans);
        color: var(--main);
    }
    .info {
        font-size: 12px;
        color: gray;
        margin-top: 3px;
    }
    .opt {
        margin-left: auto;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--trans);
        fill: var(--main);
    }
    .stat {
        border-top: 1px solid #ddd;
        padding: 15px;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .g {
        color: grey;
    }
    .click {
        color: var(--main);
        font-weight: 600;
        padding: 13px 15px;
        background-color: var(--trans);
        transition: .3s;
    }
    .click:active {
        background-color: var(--main);
        color: white;
    }
    .reaction {
        border-top: 1px solid #ddd;
        display: grid;
        grid-template-columns: 20% 20% 20% 20% 20%;
        align-items: center;
    }
    .action {
        text-align: center;
        padding: 15px 0px;
    }
    .action img {
        width: 25px;
        filter: grayscale(100%);
    }
    .a img {
        filter: none;
    }



    .write_comment {
        display: grid;
        grid-template-columns: max-content auto max-content;
        align-items: center;
        gap: 15px;
        padding: 10px 15px;
        border-top: 1px solid #ddd;
    }
    textarea {
        outline: none;
        border: 1px solid #ddd;
        border-radius: 5px;
        padding: 5px;
        font-size: 12px;
        resize: none;
        font-size: 12px;
    }
    .send {
        fill: var(--main);
    }
    .spin {
        width: 15px;
        height: 15px;
        border-width: 2px;
    }


</style>