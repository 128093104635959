<template>
    <div class="splash">
        
        <div>
            <div class="circle circle_1 ">
                <div class="circle circle_2" >
                    <div class="circle logo">
                        <img src="../../assets/ikotun_icon.png"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom">
            <div class="name">Ikotun Connect</div>
            <div class="slogan">Connect · Share · Engage · Grow</div>
        </div>

        <!-- <div class="logo">
            <img src="../../assets/ikotun_logo_1.png"/>
        </div> -->
    </div>
</template>

<script>

export default {

    data() {
        return {
            key: null
        }
    },
    activated() {
        
        this.$root.$data.is_splash = true

        if (this.$root.$data.login) {
            if (this.$root.$data.email_verified) {
                this.$router.push('/home')
            } else {
                this.$router.push('/verify')
            }
        } else {
            this.$router.push('/info')
        }
    },
    mounted() {
        
        this.$root.$data.is_splash = true

        if (this.$root.$data.login) {
            if (this.$root.$data.email_verified) {
                this.$router.push('/home')
            } else {
                this.$router.push('/verify')
            }
        } else {
            this.$router.push('/info')
        }
    }
}
</script>

<style scoped>
    .splash {
        
        height: 100vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        
        position: relative;

        background-color: white;
        /* background: url('../../assets/svg/blob-mobile.svg'); */
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        /* background: linear-gradient(to bottom left, var(--main), var(--main), tomato); */
    }

    

    .circle {
        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 50%;
    }
    .circle_1 {
        width: 130vw;
        height: 130vw;
        /* background-color: rgba(0, 0, 0, 0.1); */
    }
    .circle_2 {
        width: 90vw;
        height: 90vw;
        /* background-color: rgba(50, 41, 218, 1); */
        /* background-color: rgba(0, 0, 0, 0.1); */
    }

    .logo  {
        width: 50vw;
        height: 50vw;
        border: 3px solid #fff;
        fill: white;
    }


    .bottom {
        position: absolute;
        bottom: 20px;
        left: 20px;

        width: calc(100vw - 40px);
        color: white;
        background: linear-gradient(to right, var(--main), tomato);
        border-radius: 5px;
        padding: 10px;
        box-sizing: border-box;
    }
    .name {
        font-weight: 800;
        font-size: 14px;
    }
    .slogan {
        font-size: 12px;
        /* color: gray; */
        margin-top: 2px;
    }



    /* .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
        border-radius: 0px;
        background-color: rgba(0, 0, 0, 0.5);
        animation: grow .2s forwards;
        animation-delay: .2s;
    } */
    @keyframes grow {
        from {
            width: 100%;
            height: 100vh;
            border-radius: 0px;
        }
        to {
            width: 220px;
            height: 220px;
            border-radius: 50%;
        }
    }

    .logo img {
        width: 100px;
    }
</style>