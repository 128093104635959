<template>
    <div class="options">
        <div class="title">Post Options</div>
        <div class="main">
            <div class="item" v-if="!post.deleted" @click="copy()">
                <copy :size="'20px'"/>
                <div>
                    <div class="t">Copy Post</div>
                    <div class="label">Copy post text content</div>
                </div>
            </div>
            <div class="item" v-if="full" @click="view_comment()">
                <comment :size="'20px'"/>
                <div>
                    <div class="t">View Comments</div>
                    <div class="label">Checkout what others are saying</div>
                </div>
            </div>
            <div class="item" @click="goto_profile()">
                <user :size="'20px'" :color="'#555'"/>
                <div>
                    <div class="t">View Profile</div>
                    <div class="label">Go to @{{post.user.username}} profile</div>
                </div>
            </div>

            <div v-if="you">
                <div class="item" v-if="post.edited">
                    <edit :size="'20px'" :color="'#555'"/>
                    <div>
                        <div class="t">Edited</div>
                        <div class="label">You've edited this post</div>
                    </div>
                </div>
                <div class="item" v-else @click="edit()">
                    <edit :size="'20px'" :color="'#555'"/>
                    <div>
                        <div class="t">Edit Post</div>
                        <div class="label">Update post text content</div>
                    </div>
                </div>
                <div class="item" @click="change_settings()">
                    <settings :size="'20px'" :color="'#555'"/>
                    <div>
                        <div class="t">Post settings</div>
                        <div class="label">Change post settings</div>
                    </div>
                </div>

                <div class="item" v-if="post.deleted">
                    <remove :size="'20px'" :color="'#555'"/>
                    <div>
                        <div class="t">Deleted</div>
                        <div class="label">Post content has been removed</div>
                    </div>
                </div>
                <div class="item" v-else @click="remove_post()">
                    <remove :size="'20px'" :color="'#555'"/>
                    <div>
                        <div class="t">Delete Post</div>
                        <div class="label">Prevent users from seeing this content of this post</div>
                    </div>
                </div>
            </div>

            <div v-else>
                <div class="item" v-if="following">
                    <following :size="'20px'" :color="'#555'"/>
                    <div>
                        <div class="t">Following</div>
                        <div class="label">You are already following @{{post.user.username}} </div>
                    </div>
                </div>
                <div class="item" @click="follow()" v-else>
                    <follow :size="'20px'" :color="'#555'"/>
                    <div>
                        <div class="t">Follow Author</div>
                        <div class="label">Follow @{{post.user.username}} for better interaction</div>
                    </div>
                    <div class="spin" v-if="follow_loading"></div>
                </div>

                <div class="item">
                    <chat :size="'20px'" :color="'#555'"/>
                    <div>
                        <div class="t">Message Author</div>
                        <div class="label">Send a direct message to @{{post.user.username}} </div>
                    </div>
                </div>
                <div class="item" @click="hide_post()">
                    <hide :size="'20px'" :color="'#555'"/>
                    <div>
                        <div class="t">Hide Post</div>
                        <div class="label">Stop this post from appearing on your feed </div>
                    </div>
                </div>
                <div class="item" @click="block_user()">
                    <blockicon :size="'20px'" :color="'#555'"/>
                    <div>
                        <div class="t">Block Author</div>
                        <div class="label">Stop seeing all post from @{{post.user.username}}</div>
                    </div>
                </div>
            </div>


            <div class="item">
                <report :size="'20px'" :color="'#555'"/>
                <div>
                    <div class="t">Report Post</div>
                    <div class="label">Inform admins and moderators about this post</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Localbase from 'localbase'
let db = new Localbase('ikotun')

import copy from '../../icons/copy.vue';
import comment from '../../icons/comment.vue'
import user from '../../icons/user.vue'

import edit from '../../icons/edit.vue'
import settings from '../../icons/settings.vue'
import remove from '../../icons/delete.vue'

import follow from '../../icons/follow.vue';
import following from '../../icons/following.vue'
import chat from '../../icons/chat.vue';
import hide from '../../icons/hide.vue'
import blockicon from '../../icons/block.vue'
import report from '../../icons/report.vue'

export default {
    components: {
        copy, comment, user, 
        edit, settings, remove,
        follow, following, chat, hide, blockicon, report
    },
    props: ['post', 'settings', 'full'],
    computed: {
        you() {
            return this.$root.$data.username == this.post.user.username
        }
    },
    created() {
        const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
            this.$emit('close')
            next(false)
        })

        this.$once('hook:destroyed', () => {
            unregisterRouterGuard()
        })
    },
    mounted() {
        if (this.post.user.following.follow_id) {
            this.following = true
        }
    },
    data() {
        return {
            follow_loading: false,
            following: false
        }
    },
    methods: {
        copy() {
            if (!this.settings.copy) {
                this.copy_off(); return
            }
            let vm = this
            navigator.clipboard.writeText(this.post.text).then(function() {
                vm.copied()
                vm.$emit('close')
            })
        },
        view_comment() {
            this.$emit('close')
            
            localStorage.setItem('post', JSON.stringify(this.post))

            setTimeout(() => {
                this.$router.push('/post/' + this.post.id)
            }, 250);

        },
        goto_profile() {
            this.$emit('close')
            
            localStorage.setItem('profile', JSON.stringify(this.post.user))

            setTimeout(() => {
                this.$router.push('/profile/' + this.post.user.username)
            }, 250);
        },
        edit() {
            this.$emit('close')

            localStorage.setItem('edit', JSON.stringify(this.post))

            setTimeout(() => {
                this.$router.push('/edit_post')
            }, 250);
        },
        change_settings() {
            this.$emit('close')

            localStorage.setItem('settings', JSON.stringify(this.post))

            setTimeout(() => {
                this.$router.push('/post_settings')
            }, 250);

        },
        remove_post() {
            this.$emit('close')

            localStorage.setItem('delete', JSON.stringify(this.post))

            setTimeout(() => {
                this.$router.push('/delete_post')
            }, 250);
        },
        follow() {
            if (this.follow_loading) { return }
            this.follow_loading = true

            let form = new FormData()
            form.append('username', this.post.user.username)

            this.$http.post('/user/follow', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.following = true

                    if (r.new) {
                        this.$root.$data.followings++
                        
                        db.collection('auth').doc({ id: 1 }).update({
                            'followings': this.$root.$data.followings
                        })

                        let new_follow = {
                            'username': this.post.user.username,
                            'follow_id': r.id,
                            'follow_date': r.date
                        }

                        this.$root.$data.new_follow = new_follow

                        this.$emit('new_follow', new_follow)
                    }
                    this.followed()
                }
            })
        },
        hide_post() {
            db.collection('hidden_post').doc({ post_id: this.post.id }).get()
            .then(doc => {
                if (doc == undefined) {
                    db.collection('hidden_post').add({
                        post_id: this.post.id
                    }).then(() => {
                        this.$root.$data.new_hide = {
                            id: this.post.id
                        }
                        this.hidden()
                        this.$emit('hide_post')
                    })
                }
                else {
                    this.hidden()
                    this.$emit('hide_post')
                }
            })
        },
        block_user() {
            db.collection('blocked_users').doc({ username: this.post.user.username }).get()
            .then(doc => {
                if (doc == undefined) {
                    db.collection('blocked_users').add({
                        username: this.post.user.username
                    }).then(() => {
                        this.$root.$data.new_block = {
                            username: this.post.user.username
                        }
                        this.blocked()
                        this.$emit('block_user')
                    })
                }
            })
        }
    },
    notifications: {
        copy_off: {
            type: 'info',
            title: 'Cannot Copy',
            message: 'The author has turned off copying'
        },
        copied: {
            type: 'success',
            title: 'Copied',
            message: 'Post text content has been copied'
        },
        followed: {
            type: 'success',
            title: 'Followed',
            message: 'You are now following this user'
        },
        hidden: {
            type: 'success',
            title: 'Hidden',
            message: 'This post is now hidden'
        },
        blocked: {
            type: 'success',
            title: 'Blocked',
            message: 'You have successfully blocked this user'
        }
    }
}
</script>

<style scoped>

    .options {
        background-color: white;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        width: 100%;
        position: relative;
        z-index: 2;
        transition: .2s;
    }
    .title {
        padding: 20px;
        border-bottom: 1px solid #ddd;
        font-weight: 500;
    }
    .main {
        max-height: 50vh;
        padding: 10px 0px;
        overflow: auto;
    }
    .item {
        display: grid;
        grid-template-columns: max-content auto max-content;
        align-items: center;
        gap: 20px;
        fill: #555;
        padding: 10px 20px;
        transition: .3s;
    }
    .item:active {
        background-color: var(--trans);
    }
    .t {
        font-weight: 500;
        font-size: 14px;
    }
    .label {
        font-size: 12px;
        font-weight: 500;
        color: grey;
    }
    
    .spin {
        width: 20px;
        height: 20px;
        border-width: 2px;
    }
</style>