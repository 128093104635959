<template>
    <div class="send_message">
        <div class="title">Send Message</div>
        <div class="text">Your message will be sent as a message request to @{{ username }}</div>
        <send :can_load="true" @send="send($event)"/>
    </div>
</template>

<script>
import Localbase from 'localbase'
let db = new Localbase('ikotun')

import send from '../message/send.vue'
export default {
    components: {
        send
    },
    props: ['username'],
    data() {
        return {
            editable: true
        }
    },
    methods: {
        send(e) {

            let form = new FormData()
            form.append('type', e.type)
            form.append('text', e.text)
            form.append('username', this.username)

            this.$http.post('/chat/create', form)
            .then(data => {
                let r = data.data

                if (r.success) {
                    if (r.new) {
                        let chat = {
                            'chat_id': r.chat.chat_id,
                            'date': r.chat.date,
                            'updated_at': new Date(r.chat.updated_at).getTime(),
                            'initiator': this.$root.$data.username,
                            'user1': this.$root.$data.username,
                            'user2': this.username,
                            'is_approved': false,
                            'declined': false,
                            'declined_at': '0000-00-00',
                            'count': 1,
                            'user': this.username,
                            'new_messages': 0
                        }
                        db.collection('chats').add(chat)
                        localStorage.setItem('chat', JSON.stringify(chat))

                        db.collection('chat_users').add({
                            'chat_id': r.chat.chat_id,
                            'date': new Date().getTime(),
                            'user': this.username,
                            'nickname': r.firstname + ' ' + r.lastname,
                            'is_typing': false,
                            'online': false,
                            'last_seen': '0000-00-00',
                            'calls_allowed': false,
                            'is_blocked': false,
                            'blocked_by': '',
                            'blocked_at': '',
                            'updated_at': new Date().getTime()
                        })

                        this.$emit('set_chat', chat)

                        db.collection(r.chat.chat_id).add({
                            'date': r.message.date,
                            'chat_id': r.message.chat_id,
                            'count': r.message.count,
                            'type': r.message.type,
                            'value': r.message.value,
                            'text': r.message.text,
                            'sender': r.message.sender,
                            'receiver': this.username,
                            'message_id': r.message.message_id,
                            'sent': true,
                            'delivered': false,
                            'delivered_date': r.message.date,
                            'seen': false,
                            'seen_date': r.message.date,
                            'time': new Date(r.message.date).getTime(),
                            'notify': r.message.notify,
                            'meta': r.message.meta
                        })

                        setTimeout(() => {
                            this.$router.push('/chat/' + r.chat.chat_id)
                        }, 1000);

                    } else {
                        this.$emit('close')
                        
                        this.$router.push({
                            path: '/message',
                            query: {
                                view: 2,
                                username: this.username
                            }
                        })

                        // r.chat['user'] = this.username
                        // r.chat['date'] = r.chat.created

                        // localStorage.setItem('chat', JSON.stringify(r.chat))
                        // db.collection('chats').add(r.chat)
                        
                        // this.$emit('set_chat', r.chat)

                    }
                }
            })
        }
    }
}
</script>

<style scoped>
    .send_message {
        
        background-color: white;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        width: 100%;
        position: relative;
        z-index: 2;
        transition: .2s;
    }
    .title {
        padding: 20px;
        padding-bottom: 0px;
        font-weight: 600;
        font-size: 18px;
    }
    .text {
        padding: 10px 20px;
        padding-top: 5px;
        font-size: 14px;
        color: grey;
    }
</style>