<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            
            <linearGradient id="Gradient1" x1="0" x2="1" y1="0" y2="1">
                <stop class="stop1" offset="0%" />
                <stop class="stop3" offset="100%" />
            </linearGradient>

            <path d="m480-146.925-44.153-39.691q-99.461-90.231-164.5-155.077-65.038-64.846-103.076-115.423-38.039-50.577-53.154-92.269-15.116-41.692-15.116-84.615 
            0-85.153 57.423-142.576Q214.847-833.999 300-833.999q52.385 0 99 24.501 46.615 24.5 81 70.269 
            34.385-45.769 81-70.269 46.615-24.501 99-24.501 85.153 0 142.576 57.423Q859.999-719.153 859.999-634q0 
            42.923-15.116 84.615-15.115 41.692-53.154 92.269-38.038 50.577-102.884 
            115.423T524.153-186.616L480-146.925Z"/>

        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

    svg { 
        fill: url(#Gradient1); 
        animation: animate .4s infinite;
        animation-direction: alternate;
    }
    
    @keyframes animate {
        0% {
            /* transform: rotateZ(-10deg); */
            transform: scale(1.1);
        }
        70% {
            /* transform: scale(1.3); */
            /* transform: rotateZ(10deg); */
        }
        100% {
            /* transform: rotateZ(0deg); */
            transform: scale(1);
        }
    }

    .stop1 { stop-color: var(--main); }
    .stop3 { stop-color: var(--main); }
    
</style>