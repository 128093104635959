<template>
    <div>

        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" :width="size" :height="size">
            <path class="cls-1" d="M20,3H4A3,3,0,0,0,1,6V18a3,3,0,0,0,3,3H20a3,3,0,0,0,3-3V6A3,3,0,0,0,20,3Zm-.328,12.62a1,1,0,1,1-1.344,1.48l-4.143-3.76a3.937,3.937,0,0,1-4.37,0L5.672,17.1a1,1,0,0,1-1.344-1.48l3.956-3.591L4.327,8.43A1,1,0,1,1,5.673,6.95l4.979,4.529a2.005,2.005,0,0,0,2.7,0L18.327,6.95a1,1,0,0,1,1.346,1.48l-3.956,3.6Z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>