<template>
    <div class="card" @click="goto_profile()">
        <div class="image">
            <img :src="user.dp"/>
        </div>
        <div>
            <div class="flex">
                <div class="name">{{ user.firstname + ' ' + user.lastname }}</div>
                <verified :size="'18px'" :style="{'height': '18px'}" v-if="user.verified"/>
            </div>
            <div class="f">
                <div class="username">@{{ user.username }}</div>
                <div>·</div>
                <div class="date">{{ date }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import verified from '../../icons/verified.vue';

export default {
    components: {
        verified
    },
    props: ['user'],
    data() {
        return {
            date: ''
        }
    },
    mounted() {
        this.date = moment(this.user.date).fromNow()
    },
    methods: {
        
        goto_profile() {
            localStorage.setItem('profile', JSON.stringify(this.user))

            setTimeout(() => {
                this.$router.push('/profile/' + this.user.username)
            }, 250);
        },
    }
}
</script>

<style scoped>
    .card {
        background-color: white;
        border-radius: 5px;
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        gap: 20px;
        padding: 10px 15px;
    }
    .image {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    img {
        width: 50px;
        display: block;
    }

    .flex {
        display: flex;
        align-items: center;
        gap: 10px;
        fill: var(--main);
    }
    .name {
        font-weight: 600;
        font-size: 14px;
    }

    .f {
        display: flex;
        gap: 5px;
        
        font-size: 12px;
        color: gray;
        margin-top: 3px;
    }
    .username {
    }
</style>