<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960" >
            
            <linearGradient id="Gradient1" x1="1" x2="0" y1="0" y2="0">
                <stop class="stop1" offset="0%" />
                <stop class="stop3" offset="100%" />
            </linearGradient>

            <path d="M616.244-527.693q21.832 0 37.025-15.283 15.192-15.282 15.192-37.115 
            0-21.832-15.283-37.024t-37.115-15.192q-21.832 0-37.024 15.283-15.193 15.282-15.193 
            37.115 0 21.832 15.283 37.024t37.115 15.192Zm-272.307 0q21.832 0 37.024-15.283 
            15.193-15.282 15.193-37.115 0-21.832-15.283-37.024t-37.115-15.192q-21.832 0-37.025 
            15.283-15.192 15.282-15.192 37.115 0 21.832 15.283 37.024t37.115 15.192ZM480-420q-62.615 
            0-114.461 35.038t-76.461 92.653H342q22-37 58.5-58.5t79.5-21.5q43 0 79.5 21.5t58.5 
            58.5h52.922q-24.615-57.615-76.461-92.653Q542.615-420 480-420Zm.067 319.999q-78.836 
            0-148.204-29.92-69.369-29.92-120.682-81.21-51.314-51.291-81.247-120.629-29.933-69.337-29.933-148.173t29.92-148.204q29.92-69.369 
            81.21-120.682 51.291-51.314 120.629-81.247 69.337-29.933 148.173-29.933t148.204 
            29.92q69.369 29.92 120.682 81.21 51.314 51.291 81.247 120.629 29.933 69.337 
            29.933 148.173t-29.92 148.204q-29.92 69.369-81.21 120.682-51.291 51.314-120.629 81.247-69.337 
            29.933-148.173 29.933Z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

    svg { 
        fill: url(#Gradient1); 
        
        animation: animate 1s cubic-bezier(.36,.07,.19,.97) infinite;
        transform: translate3d(0, 0, 0);
        animation-direction: alternate;
    }
    .stop1 { stop-color: var(--main); }
    .stop3 { stop-color: var(--main); }

    
    @keyframes animate {
        10%, 90% {
            transform: translate3d(-1px, 0, 0);
        }
        
        20%, 80% {
            transform: translate3d(1px, 0, 0);
        }

        30%, 50%, 70% {
            transform: translate3d(-2px, 0, 0);
        }

        40%, 60% {
            transform: translate3d(2px, 0, 0);
        }
    }
</style>