<template>
    <div class="about">
        <div class="box">
            <div class="title">Bio</div>
            <div class="text">
                {{profile.bio}}
            </div>
        </div>
        <div class="box top">
            <div class="title">Basic Info</div>
            <div class="flex">
                <div class="icon">
                    <user :size="'15px'" :style="{'height': '20px'}"/>
                </div>
                <div>
                    <div class="value">{{profile.firstname}} {{profile.lastname}}</div>
                    <div class="label">Name</div>
                </div>
            </div>
            <div class="flex">
                <div class="icon">
                    <gender :size="'15px'" :style="{'height': '20px'}"/>
                </div>
                <div>
                    <div class="value">{{profile.gender}}</div>
                    <div class="label">Gender</div>
                </div>
            </div>
            <div class="flex">
                <div class="icon">
                    <dateicon :size="'15px'" :style="{'height': '20px'}"/>
                </div>
                <div>
                    <div class="value">{{joined}}</div>
                    <div class="label">Joined</div>
                </div>
            </div>
            <div class="flex">
                <div class="icon">
                    <birthday :size="'15px'" :style="{'height': '20px'}"/>
                </div>
                <div>
                    <div class="value">{{birthday}}</div>
                    <div class="label">Birthday</div>
                </div>
            </div>
            <div class="flex">
                <div class="icon">
                    <religion :size="'15px'" :style="{'height': '20px'}"/>
                </div>
                <div>
                    <div class="value">{{profile.religion}}</div>
                    <div class="label">Religion</div>
                </div>
            </div>
            <div class="flex">
                <div class="icon">
                    <language :size="'15px'" :style="{'height': '20px'}"/>
                </div>
                <div>
                    <div class="value">{{profile.language}}</div>
                    <div class="label">Language</div>
                </div>
            </div>
            <div class="flex">
                <div class="icon">
                    <relationship :size="'15px'" :style="{'height': '20px'}"/>
                </div>
                <div>
                    <div class="value">{{profile.marital}}</div>
                    <div class="label">Relationship</div>
                </div>
            </div>
        </div>

        <div class="box top">
            <div class="title">Contact Info</div>

            <div class="flex">
                <div class="icon">
                    <phone :size="'15px'" :style="{'height': '20px'}"/>
                </div>
                <div>
                    <div class="value">{{profile.phone}}</div>
                    <div class="label">Phone</div>
                </div>
            </div>
            <div class="flex">
                <div class="icon">
                    <email :size="'15px'" :style="{'height': '20px'}"/>
                </div>
                <div>
                    <div class="value">{{profile.email}}</div>
                    <div class="label">Email</div>
                </div>
            </div>
            <div class="flex">
                <div class="icon">
                    <location :size="'15px'" :style="{'height': '20px'}"/>
                </div>
                <div>
                    <div class="value">{{profile.location}}</div>
                    <div class="label">Location</div>
                </div>
            </div>
            <div class="flex">
                <div class="icon">
                    <whatsapp :size="'15px'" :style="{'height': '20px'}"/>
                </div>
                <div>
                    <div class="value">{{profile.whatsapp}}</div>
                    <div class="label">Whatsapp</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import user from '../../icons/user.vue'
import gender from '../../icons/gender.vue'
import dateicon from '../../icons/date.vue'
import birthday from '../../icons/birthday.vue'
import religion from '../../icons/religion.vue'
import language from '../../icons/language.vue'
import relationship from '../../icons/relationship.vue'

import phone from '../../icons/phone.vue'
import email from '../../icons/email.vue'
import location from '../../icons/location.vue'
import whatsapp from '../../icons/whatsapp.vue'

import moment from 'moment'

export default {
    components: {
        user, gender, dateicon, birthday, religion, language, relationship,
        phone, email, location, whatsapp
    },
    props: ['profile'],
    mounted() {
        if (this.profile.birthday) {
            this.birthday = moment(this.profile.birthday).format('MMMM do')
        }
        
        this.joined = moment(this.profile.created).format('Do	MMMM YYYY')
    },
    data() {
        return {
            birthday: '',
            joined: ''
        }
    }
}
</script>

<style scoped>
    .about {
        background-color: #edeef0;
        padding: 15px;
        box-sizing: border-box;
    }
    .box {
        background-color: white;
        border-radius: 5px;
        padding: 15px;
    }
    .top {
        margin-top: 15px;
    }
    .title {
        font-weight: 500;
        font-size: 18px;
    }
    .text {
        margin-top: 5px;
        font-size: 14px;
        line-height: 22px;
        color: gray;
    }

    .flex {
        display: flex;
        align-items: center;
        gap: 15px;
        margin-top: 15px;
    }
    .icon {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: var(--trans);
        fill: var(--main);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .value {
        font-weight: 500;
        font-size: 14px;
    }
    .label {
        font-size: 12px;
        color: gray;
    }
</style>