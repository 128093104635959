<template>
    <div id="app" :style="{'height': mainHeight, '--main': c.main, '--trans': c.trans, '--m': c.m, '--t': c.t}">

        <transition :name="transitionName">
            <keep-alive>
                <router-view v-keep-scroll-position :key="$route.path" class="wrapper" v-if="ready">
                </router-view>
            </keep-alive>
        </transition>
        
        <div class="popup" v-if="pop">
            <div class="dark" @click="closepop()"></div>

            <transition name="slide">
                <home_screen @close="closepop()" v-if="pop_item == 'home_screen_install'"/>
            </transition>
            
            <transition name="slide">
                <post_options @close="closepop()" @view_comment="view_comment()" 
                    @new_follow="closepop()" @hide_post="closepop()" @block_user="closepop()"
                    v-if="pop_item == 'post_options'" v-bind="pop_prop"/>
            </transition>

            <transition name="slide">
                <comment_options  @close="closepop()"
                    v-if="pop_item == 'comment_options'" v-bind="pop_prop"/>
            </transition>

            <transition name="slide">
                <request_message @close="closepop()"
                v-if="pop_item == 'request_message'" v-bind="pop_prop"/>
            </transition>

        </div>
    </div>
</template>

<script>
import Localbase from 'localbase'
let db = new Localbase('ikotun')

import moment from 'moment-timezone'


import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";


import home_screen from './components/install/home_screen.vue';


import post_options from './components/post/options.vue'
import comment_options from './components/post/comment_options.vue'

import request_message from './components/message/request_message.vue';

// import 'swiper/css/swiper.css';

import 'vue-croppa/dist/vue-croppa.css';


export default {
    components: {
        home_screen,
        post_options,
        comment_options,
        request_message
    },
    created() {

        window.addEventListener('beforeinstallprompt', (e) => {
            console.log('before install');

            e.preventDefault();
            
            this.$root.$data.pwa_install_prompt = e;
            
        });
        
        window.addEventListener("resize", this.setHeight);
        this.setHeight()

        
        const firebaseConfig = {
            apiKey: "AIzaSyDhYYVe2Yw20MsmjkwuqorrGxM-QEw2fZY",
            authDomain: "ikotun-connect-2e817.firebaseapp.com",
            projectId: "ikotun-connect-2e817",
            storageBucket: "ikotun-connect-2e817.appspot.com",
            messagingSenderId: "109109645474",
            appId: "1:109109645474:web:aee125ce411b278ba1c7fb",
            measurementId: "G-6J24MPB51J"
        };

        
        initializeApp(firebaseConfig);

        const messaging = getMessaging();
        onMessage(messaging, (payload) => {
            console.log('Message received. ', payload);
            // ...
        });

        getToken(messaging, { vapidKey: 'BEa5R7j07wwDh7zLOP4Nth_zAs72-IUuHkgs3dAUAt2T93lDHspw8jNWhQGoPMQvENnmkqPOdPs7v2NQlGktr2o' })
        .then((currentToken) => {
            if (currentToken) {
                
                console.log("Token is:", currentToken);
                this.notification_id = currentToken
                
                if (this.key) {
                    this.$socket.emit('authenticate', {
                        'username': this.$root.$data.username,
                        'Auth': this.key,
                        'player_id': this.notification_id,
                        'app_version': this.$root.$data.app_version
                    })
                }
                
            } else {
                
                console.log('No registration token available. Request permission to generate one.');
                
            }
        }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
            
        });


        const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
            if (this.pop) {
                this.closepop()
                next(false)
            } else {
                next()
            }
        })
        this.$once('hook:destroyed', () => {
            unregisterRouterGuard()
        })

        
    },
    unmounted() {
        window.removeEventListener('resize', this.setHeight);
    },
    mounted() {
        this.init_splash()
    },
    computed: {
        c() {
            return this.$root.$data.c
        },
        root_pop_item() {
            return this.$root.$data.pop_item
        },
        pop_prop() {
            return this.$root.$data.pop_prop
        }
    },
    watch: {
        root_pop_item(val) {
            if (this.$root.$data.pop) {
                this.togglepop(val)
            }
        },
        $route(to, from) {
            
            let transition = ''
            if (this.history.length == 0) {
                if (to.meta.transition == 'fade') {
                    transition = 'fade_in'
                } else {
                    transition = 'page_in'
                }
                this.history.unshift(from.path)
            } else {
                let h = this.history[0]
                if (h == to.path) {
                    // if (to.meta.transition == 'fade') {
                    //     transition = 'fade_in'
                    // } else {
                    //      transition = 'page_out'
                    // }
                    
                    if (from.meta.transition == 'fade') {
                        transition = 'fade_in'
                    } else {
                        transition = 'page_out'
                    }
                    
                    this.history.shift()
                } else {
                    if (to.meta.transition == 'fade') {
                        transition = 'fade_in'
                    } else {
                        transition = 'page_in'
                    }

                    this.history.unshift(from.path)
                }
            }
            
            this.transitionName = transition

        },
    },
    data() {
        return {
            mainHeight: '',
            ready: false,
            key: '',
            notification_id: '',

            transitionName: '',
            history: [],

            pop: false,
            pop_item: ''
        }
    },
    methods: {

        
        setHeight() {
            this.mainHeight = window.innerHeight + "px"

            this.$root.$data.mainHeight = this.mainHeight
        },
        
        togglepop(what) {
            this.pop = true
            setTimeout(() => {
                this.pop_item = what
            }, 100);
        },
        closepop() {
            this.$root.$data.pop_item = ''
            this.pop_item = ''
            setTimeout(() => {
                this.$root.$data.pop = false
                this.pop = false
            }, 200);
        },

        init_splash() {

            let chat_requests = localStorage.getItem('chat_requests')
            this.$root.$data.chat_requests = chat_requests == null ? 0 : chat_requests

            db.collection('auth').doc({ id: 1 }).get().then(document => {
                if (document == undefined) {
                    this.$root.$data.login = false
                    
                } else {
                    this.$root.$data.login = true
                    // this.$root.$data.username = localStorage.getItem('username')
                    this.$http.defaults.headers.common['Auth'] = document.key
                    this.key = document.key
                    this.$root.$data.username = document.username

                    
                    if (document.email_verified) {
                        this.$root.$data.email_verified = document.email_verified
                        this.$root.$data.created = document.created
                        this.$root.$data.verified = document.verified
                        this.$root.$data.dp = document.dp
                        this.$root.$data.cover = document.cover
                        this.$root.$data.firstname = document.firstname
                        this.$root.$data.lastname = document.lastname
                        this.$root.$data.gender = document.gender
                        this.$root.$data.email = document.email
                        this.$root.$data.username = document.username
                        this.$root.$data.location = document.location

                        this.$root.$data.posts = document.posts
                        this.$root.$data.comments = document.comments
                        this.$root.$data.followers = document.followers
                        this.$root.$data.followings = document.followings
                        
                    } else {
                        this.$root.$data.username = document.username
                        this.$root.$data.email = document.email
                        
                    }
                }

                setTimeout(() => {
                    this.ready = true
                }, 100);
            })
        }
    },
    sockets: {
        connect: function () {
            
            
            setTimeout(() => {
                if (this.key) {

                    this.$socket.emit('authenticate', {
                        'username': this.$root.$data.username,
                        'Auth': this.key,
                        'player_id': this.notification_id,
                        'app_version': this.$root.$data.app_version
                    })
                }
            }, 4000);
        },
        notification: function (data) {
            this.$root.$data.chat_requests = data.chat_requests

            if (data.chat_requests > 0) {
                this.$root.$data.message_notification = true
            }

            let form = new FormData()
            form.append('date', moment(data.last_seen).format('YYYY-MM-DD HH:mm:ss'))

            this.$http.post('/chat/new_messages', form)
            this.$http.post('/chat/updated_chats', form)
        },
        new_chat_request: function (data) {
            localStorage.setItem('chat_requests', data.chat_requests)

            if (this.$route.name != 'message') {
                this.$root.$data.message_notification = true
            }

            this.$root.$data.chat_requests = data.chat_requests
        },
        chat_updated: function (data) {
            db.collection('chats').doc({'chat_id': data.chat_id}).update({
                
                'updated_at': new Date(data.updated_at).getTime(),
                'is_approved': data.is_approved,
                'declined': data.declined
            })

            this.$root.$data.chat_updated = data
        },
        new_message: function(data_received) {
            let data = data_received.message

            let message = {
                'date': data.date,
                'chat_id': data.chat_id,
                'count': data.count,
                'type': data.type,
                'value': data.value,
                'text': data.text,
                'sender': data.sender,
                'receiver': data.receiver,
                'message_id': data.message_id,
                'sent': true,
                'delivered': false,
                'delivered_date': null,
                'seen': false,
                'seen_date': null,
                'time': new Date(data.date).getTime(),
                'notify': data.notify,
                'meta': data.meta
            }

            if (this.$route.name == 'message' || this.$route.name == 'chat') {
                this.$root.$data.message_notification = false
            } else {
                this.$root.$data.message_notification = true
            }

            this.$root.$data.new_message_received = message
            
            db.collection(data.chat_id).add(message).then(() => {
                db.collection('chats').doc({'chat_id': data.chat_id}).update({
                    'updated_at': new Date(data.date).getTime(),
                    'count': data.count
                })

                this.$socket.emit('message_delivered', {
                    'message_id': message.message_id,
                    'chat_id': data.chat_id
                })
            })

        },
        message_delivered_broadcasted: function(data) {
            db.collection(data.chat_id).doc({'message_id': data.message_id}).update({
                'delivered_date': data.date,
                'delivered': true
            }).then(() => {
                this.$root.$data.message_delivered_info = {
                    'message_id': data.message_id,
                    'chat_id': data.chat_id,
                    'date': data.date
                }
            })
        },
        message_delivered_to_reciever: function(data) {
            db.collection(data.chat_id).doc({'message_id': data.message_id}).update({
                'delivered_date': data.date,
                'delivered': true
            }).then(() => {
                this.$root.$data.message_delivered_info = {
                    'message_id': data.message_id,
                    'chat_id': data.chat_id,
                    'date': data.date
                }
            })
        },

        message_seen_broadcasted: function(data) {
            db.collection(data.chat_id).doc({'message_id': data.message_id}).update({
                'seen_date': data.date,
                'seen': true
            }).then(() => {
                this.$root.$data.message_seen_info = {
                    'message_id': data.message_id,
                    'chat_id': data.chat_id,
                    'date': data.date
                }
            })
        },
        message_seen_by_reciever: function(data) {
            db.collection(data.chat_id).doc({'message_id': data.message_id}).update({
                'seen_date': data.date,
                'seen': true
            }).then(() => {
                this.$root.$data.message_seen_info = {
                    'message_id': data.message_id,
                    'chat_id': data.chat_id,
                    'date': data.date
                }
            })
        }
    }
}
</script>

<style>

@import 'assets/css/swiper.css';
/* @import 'assets/css/animate.css'; */

/* @font-face {
    font-family: MuseoModerno;
    src: url('./assets/fonts/MuseoModerno.ttf');
} */

@font-face {
    font-family: 'Montserrat';
    src: url('/assets/fonts/montserrat.ttf');
}

#app {
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #333;
    /* background-color: white; */
    
    -webkit-user-select: none;
    -ms-user-select: none; 
    user-select: none; 
    

    /* height: 100%; */

    width: 100vw;
    max-width: var(--max-width);
    margin: 0 auto;


    /* color: #6a0dad; */
}
body {
    

    padding: 0px;
    margin: 0px;
    background-color: whitesmoke;
}

:root {
    --max-width: 900px
}


a {
    color: inherit;
    text-decoration: none;
    width: fit-content;
}
a:active, a:focus {
    background-color: unset;
    color: unset;
    -webkit-tap-highlight-color: transparent;
}

textarea, input, select {
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    resize: none;
}



.wrapper {
    transition: height .3s;
    width: 100vw;
}



.page_in-enter-active {
    position: absolute;
    z-index: 99;

    /* -webkit-transition: all 0.2s;
    transition: all 0.2s; */
    will-change: transform;
    animation: slide_in .3s linear;
}
.page_in-leave-active {
    position: relative;

    /* -webkit-transition: all 0.2s;
    transition: all 0.2s; */

    will-change: transform;
    animation: slide_out .3s;
}
.page_in-leave-active:after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    
    /* -webkit-transition: all 0.2s;
    transition: all 0.2s; */

    animation: darkchange .3s reverse;
}


.page_out-enter-active {
    position: absolute;
    
    /* -webkit-transition: all 0.2s;
    transition: all 0.2s; */

    will-change: transform;
    animation: slide_out .3s reverse;
}
.page_out-enter-active:after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    
    /* -webkit-transition: all 0.2s;
    transition: all 0.2s; */

    animation: darkchange .3s;
}

.page_out-leave-active {
    position: relative;
    z-index: 99;
    
    /* -webkit-transition: all 0.2s;
    transition: all 0.2s; */

    will-change: transform;
    animation: slide_in .3s linear reverse;
}

@keyframes slide_in {
    from {
        position: absolute;
        transform: translateX(100%)
    }
    to {
        position: absolute;
        transform: translateX(0%)
    }
}
@keyframes slide_out {
    from {
        position: absolute;
        transform: scale(1) translateX(0%);
    }
    to {
        position: absolute;
        transform: scale(0.9) translateX(-20%);
    }
}

@keyframes darkchange {
    from {
        background-color: rgba(0, 0, 0, 0.5);
    }
    to {
        background-color: rgba(0, 0, 0, 0);
    }
}




.slide_up-enter-active {
    animation: in .3s linear;
}
.slide_up-leave-active {
    animation: out .5s;
}
@keyframes in {
    from {
        /* position: absolute; */
        /* left: 100%; */
        transform: scale(.9);
        opacity: 0;
        /* opacity: 0; */
    }
    to {
        /* position: absolute; */
        /* left: 0px; */
        transform: scale(1);
        opacity: 1;
        /* opacity: 1; */
    }
}


.spin {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 3px solid var(--main);
    border-top-color: transparent;
    animation: rotate .7s infinite linear;
}
@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}



.pagination-active {
    opacity: 1;
    background-color: var(--main);
}





.popup {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    z-index: 3;
    
}
.popup > div {

    max-width: var(--max-width);
    margin: 0 auto;
}
.dark {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    max-width: 100% !important;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

.slide-enter-active {
    animation: move .3s;
}
.slide-leave-active {
    animation: .2s move reverse;
}
@keyframes move {
    from {
        margin-bottom: -50vh;
    }
    to {
        margin-bottom: 0px;
    }
}

@media screen and (min-width: 700px) {
    #app {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
}
</style>
