
export default  {
    data() {
        return {
            pStart: { x: 0, y: 0 },
            pStop: { x: 0, y: 0 },
            scrollTop: { s: 0, e: 0}
        }
    },
    methods: {
        swipeStart() { },
        swipeEnd() {},
        swipeCheck() {},
        isPullDown() {},

        // swipeStart(e) {
        //     this.scrollTop['s'] = this.$refs.container.scrollTop

        //     if (typeof e["targetTouches"] !== "undefined") {
        //         var touch = e.targetTouches[0];
        //         this.pStart.x = touch.screenX;
        //         this.pStart.y = touch.screenY;
        //     } else {
        //         this.pStart.x = e.screenX;
        //         this.pStart.y = e.screenY;
        //     }
        // },

        // swipeEnd(e) {
            
        //     this.scrollTop['e'] = this.$refs.container.scrollTop

        //     if (typeof e["changedTouches"] !== "undefined") {
        //         var touch = e.changedTouches[0];
        //         this.pStop.x = touch.screenX;
        //         this.pStop.y = touch.screenY;
        //     } else {
        //         this.pStop.x = e.screenX;
        //         this.pStop.y = e.screenY;
        //     }

        //     if (this.scrollTop.s == 0 && this.scrollTop.e == 0) {
        //         this.swipeCheck();
        //     }
        // },

        // swipeCheck() {
        //     var changeY = this.pStart.y - this.pStop.y;
        //     var changeX = this.pStart.x - this.pStop.x;
        //     if (this.isPullDown(changeY, changeX)) {
        //         this.refresh()
        //     }
        // },

        // isPullDown(dY, dX) {
        //     return (
        //         dY < 0 &&
        //         ((Math.abs(dX) <= 50 && Math.abs(dY) >= 200) ||
        //         (Math.abs(dX) / Math.abs(dY) <= 0.3 && dY >= 60))
        //     );
        // },
    }
};
