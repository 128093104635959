<template>
    <div :style="{'height': size}">

        <svg style="enable-background:new 0 0 32 32;" version="1.1" viewBox="0 0 32 32" 
        xml:space="preserve" xmlns="http://www.w3.org/2000/svg" :width="size" :height="size"
        xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Layer_1"/>
            <g id="icons">
                <path class="st0" d="M16.4,29.8l1.5-1.5c0.3-0.3,0.3-0.7,0-1l-9.4-9.5h20.9c0.4,0,0.7-0.3,0.7-0.7v-2.1   
                c0-0.4-0.3-0.7-0.7-0.7H8.4l9.4-9.5c0.3-0.3,0.3-0.7,0-1l-1.5-1.5c-0.3-0.3-0.7-0.3-1,0L2.2,15.5c-0.3,0.3-0.3,0.7,0,1l13.1,13.3   
                C15.6,30.1,16.1,30.1,16.4,29.8z"/>
            </g>
        </svg>


    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>