<template>
    <div class="learn">
        <div class="head">
            <div class="back" @click="back()">
                <back :size="'20px'"/>
            </div>
            <div class="title">Activity Score Info</div>
        </div>
        
        <div class="main">
            <div class="box">
                <div class="box_title">Unleashing User Engagement</div>
                <div class="text">
                    Welcome to our dynamic Activity Score feature! We've designed a system that rewards 
                    users for their active engagement within the Ikotun Connect app. 
                    The Activity Score serves as a measure of user involvement and interaction, 
                    showcasing the value they bring to our vibrant community.
                </div>
            </div>
            <div class="box">
                <div class="box_title">How Activity Score Works</div>
                <div class="text">
                    The Activity Score increases when other users actively interact with the content 
                    created by a user. Each like, comment, react, or any form of engagement adds to 
                    the user's Activity Score. It's a reflection of how their contributions resonate 
                    with others and make a positive impact on the community.
                </div>
            </div>
            <div class="box">
                <div class="box_title">The Importance of Activity Score</div>
                <div class="text">
                    The Activity Score holds significant importance within our app. It serves as a 
                    gauge of a user's level of engagement and influence. Users with higher Activity 
                    Scores often command attention, as their contributions are highly valued by the 
                    community. It acts as a measure of reputation and showcases the user's commitment 
                    to actively participate and contribute to the platform.

                </div>
            </div>
            <div class="box">
                <div class="box_title">Monthly Election Selection</div>
                <div class="text">
                    At the end of each month, the users with the highest Activity Scores are 
                    automatically selected for the monthly election. This selection process ensures 
                    that individuals who consistently engage and contribute are given an opportunity 
                    to vie for leadership positions. It incentivizes users to stay active and maintain 
                    their involvement, fostering healthy competition and a sense of achievement.

                </div>
            </div>
            <div class="box">
                <div class="box_title">Resetting Activity Score</div>
                <div class="text">
                    To maintain fairness and encourage continuous engagement, the Activity Score is 
                    reset every month. This allows everyone to start afresh and have an equal chance 
                    to earn a high score. The monthly reset ensures that users have ongoing 
                    opportunities to showcase their dedication, creativity, and influence within 
                    the community.
                </div>
            </div>
            <div class="box">
                <div class="box_title">Encouraging Meaningful Interactions</div>
                <div class="text">
                    Beyond the monthly election, the Activity Score encourages users to build 
                    meaningful connections and foster interactions. It promotes a sense of community 
                    engagement, as users strive to create valuable and engaging content that resonates 
                    with others. The score serves as a barometer for gauging the impact and influence 
                    a user has on their peers.
                </div>
            </div>
            <div class="box">
                <div class="box_title">Unleash Your Influence</div>
                <div class="text">
                    Our Activity Score feature is at the core of our app, driving user engagement and 
                    fostering a thriving community. It rewards users for their contributions, 
                    determines monthly election candidates, and promotes continuous involvement. 
                    Embrace the opportunity to increase your Activity Score, connect with others, 
                    and make a lasting impact within our vibrant community. Together, let's unleash 
                    our influence and shape the future of our platform!

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import back from '../../icons/back.vue';

export default {
    components: {
        back
    },
    methods: {
        back() {
            this.$router.go(-1)
        }
    }
}
</script>

<style scoped>

    .head {
        text-align: center;
        align-items: center;
        padding: 15px 15px;
        border-bottom: 1px solid #ddd;
        fill: var(--main);
        background-color: white;

        position: relative;
    }
    .back {
        position: absolute;
        left: 15px;
        top: 15px;
    }
    .title {
        font-weight: 500;
    }

    .main {
        height: calc(100vh - 51px);
        box-sizing: border-box;
        overflow: auto;
        background-color: #edeef0;
    }
    .l {
        padding: 50px 0px;
    }

    .box {
        margin-top: 10px;
        padding: 15px;
        background-color: white;
    }
    .box_title {
        font-weight: 500;
    }
    .text {
        margin-top: 15px;
        font-size: 15px;
        line-height: 22px;
        color: gray;
    }
</style>