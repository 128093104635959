<template>
    <div class="media">
        <div class="image" v-if="media.length == 1">
            <ik-image 

                loading="lazy"
                :lqip="{ active:true }"
                :transformation="[ { width:width } ]"
                :src="media[0].url" />
        </div>
        
            
        <swiper v-if="media.length > 1" ref="mySwiper" :options="swiperOptions" class="swiper">
            <swiper-slide class="slide" v-for="(item, i) in media" :key="i">
                
                <div class="image">
                    <ik-image 

                        loading="lazy"
                        :lqip="{ active:true }"
                        :transformation="[ { width:width } ]"
                        :src="item.url" />
                </div>
            </swiper-slide>
            
            <div class="swiper-pagination" slot="pagination"></div>

        </swiper>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

export default {
    components: {
        Swiper, SwiperSlide
    },
    props: ['media'],
    data() {
        return {
            width: 0,
            
            swiperOptions: {
                autoHeight: true,
                spaceBetween: 12,
                pagination: {
                    el: '.swiper-pagination',
                    bulletActiveClass: 'pagination-active',
                    clickable: true
                }
            }
        }
    },
    mounted() {
        this.width = window.innerWidth
    }
}
</script>

<style scoped>

    .image img {
        width: 100%;
        display: block;
    }

    .swiper {
        padding: 0px 15px;
        padding-bottom: 25px;
    }
    .slide {
        border-radius: 10px;
        overflow: hidden;
    }
    .swiper-pagination {
        bottom: 5px;
    }
</style>