<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            
            <linearGradient id="Gradient1" x1="0" x2="1" y1="0" y2="1">
                <stop class="stop1" offset="0%" />
                <stop class="stop3" offset="100%" />
            </linearGradient>

            <path d="m480-121-41-37q-105.768-97.121-174.884-167.561Q195-396 154-451.5T96.5-552Q80-597 
            80-643q0-90.155 60.5-150.577Q201-854 290-854q57 0 105.5 27t84.5 78q42-54 89-79.5T670-854q89 
            0 149.5 60.423Q880-733.155 880-643q0 46-16.5 91T806-451.5Q765-396 695.884-325.561 626.768-255.121 
            521-158l-41 37Zm0-79q101.236-92.995 166.618-159.498Q712-426 750.5-476t54-89.135q15.5-39.136 
            15.5-77.72Q820-709 778-751.5T670.225-794q-51.524 0-95.375 31.5Q531-731 
            504-674h-49q-26-56-69.85-88-43.851-32-95.375-32Q224-794 182-751.5t-42 108.816Q140-604 
            155.5-564.5t54 90Q248-424 314-358t166 158Zm0-297Z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

    svg { 
        fill: url(#Gradient1); 
        animation: animate .4s infinite;
        animation-direction: alternate;
    }
    
    @keyframes animate {
        0% {
            /* transform: rotateZ(-10deg); */
            transform: scale(1.1);
        }
        70% {
            /* transform: scale(1.3); */
            /* transform: rotateZ(10deg); */
        }
        100% {
            /* transform: rotateZ(0deg); */
            transform: scale(1);
        }
    }

    .stop1 { stop-color: var(--main); }
    .stop3 { stop-color: tomato; }
    
</style>